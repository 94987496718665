import { AxiosInstance } from "axios";
import type { GetStrategiesRequest, Strategy } from "../types";

/**
 * `StrategyService` provides methods for performing strategy-related operations.
 * It uses the provided Axios instance to make HTTP requests.
 */
export default class StrategyService {
  axios: AxiosInstance;

  /**
   * Constructs a new instance of `Strategy`.
   *
   * @param axios - An Axios instance used for making HTTP requests.
   */
  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getStrategies({ skip, limit }: GetStrategiesRequest) {
    return (
      await this.axios.get<Strategy[]>(
        `/strategies/?skip=${skip}&limit=${limit}`
      )
    ).data;
  }
  
}
