import { toast } from 'react-toastify';

const notification = {
    notify: (message: string, duration = 6000, id?: string) => {
        toast.info(message, { autoClose: duration, toastId: id });
    },
    notifyError: (message: string, duration = 6000, id?: string) => {
        toast.error(message, { autoClose: duration });
    },
    notifySuccess: (message: string, duration = 6000, id?: string) => {
        toast.success(message, { autoClose: duration })
    }
};

export default notification;
