import { InputField } from "modules/@core/components/InputField";
import { LoginCredentialsDTO } from "../types";
import { useFormik } from "formik";
import ForgotPasswordSchema from "../models/forgotPasswordSchema";
import { useState } from "react";
import notification from "modules/@core/lib/notifications";
import { useAuth } from "../providers";
import Logo from "assets/icons/logo.png";
import { Button } from "modules/@core/components/Button";
import { useNavigate } from "react-router-dom";

interface FormValues {
  email: string;
}

export const RecoverPasswordForm = () => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const initialValues: FormValues = {
    email: "",
  };

  const { authService } = useAuth();

  const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<Pick<LoginCredentialsDTO, "email">>({
      initialValues: initialValues,
      validationSchema: ForgotPasswordSchema,
      onSubmit: (values) => {
        recoverPassword(values.email);
      },
    });

  const recoverPassword = async (email: string) => {
    setIsRequestLoading(true);
    try {
      await authService.recoverPassword(email);
      notification.notify("Password Recovery Email Sent");
    } catch (err) {
      notification.notifyError("Unable to recover password");
      console.log(err);
      setIsRequestLoading(false);
    } finally {
      setIsRequestLoading(false);
    }
  };
  return (
    <div className="py-7 px-7 w-full">
      <img src={Logo} alt="Logo" className="h-12 mb-5" />
      <h3 className="font-bold text-lg my-2"> Recover Password </h3>
      <p className="text-sm">
        Enter the email address associated with your account and we’ll send you
        an email with instructions on how to recover your password
      </p>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Email"
          name="email"
          className="my-3"
          placeholder="Email Address"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email ? errors.email : undefined}
        />

        <div className="flex align-center gap-3">
          <Button
            type="submit"
            loading={isRequestLoading}
            className="mt-5"
            disabled={isRequestLoading}
          >
            {isRequestLoading ? "Loading..." : "Send Email"}
          </Button>
          <Button
            onClick={() => navigate("/auth/login")}
            variant="secondary"
            type="button"
            className="mt-5"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
