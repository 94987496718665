import React from "react";
import { Select, SelectProps } from "@mantine/core";

export type SelectItem = {
  value: string;
  label: string;
};

interface FilterSelectProps extends SelectProps {
  data: SelectItem[];
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
}

export const FilterSelect = ({
  data,
  value,
  onChange,
  style,
  ...props
}: FilterSelectProps) => {
  return (
    <Select
      value={value}
      onChange={(val) => onChange(val as string)}
      data={data.map((item) => ({
        value: item.value.toString(),
        label: item.label,
      }))}
      styles={{
        input: {
          border: "none",
          fontSize: "0.75rem",
          fontWeight: "bold",
          color: "rgb(107 114 128)",
          background: "rgb(229 231 235)",
        },
      }}
      style={style}
      {...props}
    />
  );
};
