import { PasswordInput } from "@mantine/core";
import type { PasswordInputProps } from "@mantine/core";

type PasswordFieldProps = PasswordInputProps& React.RefAttributes<HTMLInputElement>;

export const PasswordField = ({ ...props }: PasswordFieldProps) => {
  return (
    <PasswordInput      styles={{
        input: { padding: "20px 10px" },
        label: {
          fontSize: "14px",
          margin: "5px 0px",
          fontWeight: "600",
          color: "#414141",
        },
      }}
      {...props}
    />
  );
};
