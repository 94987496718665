import { LoginForm } from "modules/auth/components/LoginForm";
import Logo from "assets/icons/logo.png";

export const LoginPage = () => {
  return (
    <main className="md:bg-primary-500 bg-white h-screen overflow-hidden">
      <div className="grid grid-cols-1 items-center md:grid-cols-5">
        <section className="bg-white col-span-2 rounded-lg h-screen px-12 py-5">
          <div className="flex items-center">
            <img src={Logo} alt="Logo" className="h-12" />
            <p className="font-bold text-primary-500  "> Alpha Quants</p>
          </div>
          <LoginForm />
        </section>
        <section className="col-span-3"> </section>
      </div>
    </main>
  );
};
