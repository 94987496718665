import React, { useState } from "react";

interface PaginationProps {
  totalPages: number; // Total number of pages
  initialPage?: number; // Initial page
  onPageChange: (page: number) => void; // Callback when the page changes
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  initialPage = 1,
  onPageChange,
}) => {
  const [currentPage, setCurrentPage] = useState(initialPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => {
      const newPage = Math.max(1, prev - 1);
      onPageChange(newPage);
      return newPage;
    });
  };

  const handleNext = () => {
    setCurrentPage((prev) => {
      const newPage = Math.min(totalPages, prev + 1);
      onPageChange(newPage);
      return newPage;
    });
  };

  return (
    <div className="items-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex ">
      <span className="block">
        Page {currentPage} of {totalPages}
      </span>
      <div className="space-x-1">
        <button
          title="previous"
          type="button"
          className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          <svg
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </button>
        <button
          title="next"
          type="button"
          className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          <svg
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
