import React, { createContext, useContext, useState } from "react";
import BrokerService from "modules/broker/services";
import { Broker } from "../types";

interface Props {
  children: React.ReactNode;
  brokerService: BrokerService;
}

interface BrokersContextType {
  brokers: Broker[];
  setBrokers: (brokers: Broker[]) => void;
  brokerService: BrokerService;
}

/**
 * Create a new context for user data and services, with an undefined default value.
 */
const BrokersContext = createContext<BrokersContextType | undefined>(undefined);

/**
 * A component to provide the brokers context to its descendants.
 * @param {Props} props - The props for the component.
 */
export const BrokersContextProvider = ({ children, brokerService }: Props) => {
  const [brokers, setBrokers] = useState<Broker[]>([]); // The current user, managed as state.

  return (
    <BrokersContext.Provider value={{ brokerService, brokers, setBrokers }}>
      {children}
    </BrokersContext.Provider>
  );
};

export function useBrokersService(): BrokersContextType {
  const context = useContext(BrokersContext);
  if (!context) {
    throw new Error(
      "useBrokersService must be used within a BrokersContextProvider."
    );
  }
  return context;
}
