import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { UserInvitation } from "../types";
import { userQueryKeys } from ".";

const getUserInvites = async () => {
  return (await axios.get<UserInvitation[]>("/user/invitations")).data;
};

export const useGetUserInvites = () => {
  return useQuery<UserInvitation[], Error>({
    queryKey: userQueryKeys.userInvites,
    queryFn: getUserInvites,
  });
};
