import { InputField } from "modules/@core/components/InputField";
import { StrategyType } from "../types";

interface StrategyInputFieldProps {
  strategyName: string;
}

export const StrategyInputField = ({
  strategyName,
  setFieldValue,
}: StrategyInputFieldProps & {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) => {
  switch (strategyName) {
    case StrategyType.MOVING_AVERAGE_CROSSOVER:
      return (
        <>
          <InputField
            label="Fast Period"
            name="parameters.fast_period"
            onChange={(e) =>
              setFieldValue("parameters.fast_period", e.target.value)
            }
          />
          <InputField
            label="Slow Period"
            name="parameters.slow_period"
            onChange={(e) =>
              setFieldValue("parameters.slow_period", e.target.value)
            }
          />
        </>
      );
    case StrategyType.BOLLINGER_BANDS:
      return (
        <>
          <InputField
            label="Window"
            name="parameters.window"
            onChange={(e) => setFieldValue("parameters.window", e.target.value)}
          />
          <InputField
            label="Standard Deviation"
            name="parameters.std"
            onChange={(e) => setFieldValue("parameters.std", e.target.value)}
          />
        </>
      );
    case StrategyType.EXPONENTIAL_MOVING_AVERAGE:
      return (
        <InputField
          label="Exponential Moving Average Period"
          name="parameters.ema_period"
          onChange={(e) =>
            setFieldValue("parameters.ema_period", e.target.value)
          }
        />
      );
    default:
      return <></>;
  }
};
