import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { User } from "../types";
import { userQueryKeys } from ".";

const getUsers = async () => {
  return (await axios.get<User[]>("/user/users")).data;
};

export const useGetUsers = () => {
  return useQuery<User[], Error>({
    queryKey: userQueryKeys.users,
    queryFn: getUsers,
  });
};
