import { getFormattedDate } from "modules/@core/util/date";
import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";

type ChartDataPoint = {
  month: string;
  portfolioValue: number;
};

type PortfolioOverviewProps = {
  portfolioValue: number;
  dailyChange: number;
  data: ChartDataPoint[];
};

// Define CustomTooltipProps based on the shape of your data
type CustomTooltipProps = TooltipProps<number, string>;

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // Customize the tooltip display based on your payload's shape
    const value = payload[0].value ?? 0;
    return (
      <div className="bg-white p-2 border rounded shadow-lg">
        <p className="label">{`$${value.toLocaleString()}`}</p>
      </div>
    );
  }

  return null;
};

export const PortfolioOverview: React.FC<PortfolioOverviewProps> = ({
  portfolioValue,
  dailyChange,
  data,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  // Hover handler for the area chart
  const handleMouseHover = (index: number | undefined) => {
    setActiveIndex(index ?? -1);
  };

  // Determine the display value based on active index or default to the last value
  const displayPortfolioValue =
    activeIndex >= 0 ? data[activeIndex].portfolioValue : portfolioValue;
  // Calculate change and percentage change

  // Ensure data[0] exists and its portfolioValue is not zero to avoid dividing by zero
  const initialPortfolioValue = data.length > 0 ? data[0].portfolioValue : null;
  const portfolioChange = dailyChange;
  // Calculate the percentage change only if initialPortfolioValue is not zero
  const portfolioChangePercentage = initialPortfolioValue
    ? ((portfolioChange / initialPortfolioValue) * 100).toFixed(2)
    : "0.00";

  // Color class based on the change
  const changeColorClass =
    portfolioChange >= 0 ? "text-green-500" : "text-red-500";

  return (
    <article className="bg-white p-6 rounded-lg border border-gray-200">
      <header className="mb-5">
        <h3 className="text-gray-700 font-semibold text-lg">Your Portfolio</h3>
        <div className="flex gap-3 my-2">
          <p className="portfolio-value text-xl  font-bold text-gray-600">
            <span className="mr-2">$</span>
            {displayPortfolioValue.toLocaleString()}
          </p>
          <span className={`block font-semibold text-sm ${changeColorClass}`}>
            {portfolioChange >= 0 ? "+" : ""}$
            {Math.abs(portfolioChange).toLocaleString()} (
            {portfolioChangePercentage}%)
          </span>
        </div>
        <p className="text-xs font-semibold text-gray-400">
          {getFormattedDate(new Date())}
        </p>
      </header>
      <div className="w-full h-64">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            onMouseMove={(e) => handleMouseHover(e?.activeTooltipIndex)}
            onMouseLeave={() => setActiveIndex(-1)}
          >
            <defs>
              <linearGradient id="colorPortfolio" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#F59E0B" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#F59E0B" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke="#D1D5DB"
              vertical={false}
            />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              stroke="#6B7280"
              padding={{ left: 10, right: 10 }}
            />
            <YAxis
              stroke="#6B7280"
              padding={{ top: 10, bottom: 10 }}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip content={CustomTooltip} />
            <Area
              type="monotone"
              dataKey="portfolioValue"
              stroke="#F59E0B"
              strokeWidth={4}
              fillOpacity={1}
              fill="url(#colorPortfolio)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </article>
  );
};
