import React, { useState } from "react";
import { Popover } from "@mantine/core";
import { DotsThreeOutlineVertical } from "@phosphor-icons/react";
import Pagination from "./Pagination";
import { EmptyTableContent } from "./EmptyTableContent";

export type TableColumn<T> = {
  title: string;
  render: (data: T) => React.ReactNode;
  expand?: (data: T) => React.ReactNode;
};

export type TableAction<T> = {
  label: string;
  handler: (data: T) => void;
};

type DataTableProps<T> = {
  tableHeader?: string;
  data: T[];
  columns: TableColumn<T>[];
  actions?: TableAction<T>[];
  itemsPerPage: number;
  emptyTableElement?: JSX.Element;
};

export function DataTable<T>({
  data,
  columns,
  actions,
  itemsPerPage,
  tableHeader,
  emptyTableElement = (
    <EmptyTableContent
      heading="No Records Found"
      body="There are no records to display at this time"
      imageUrl=""
    />
  ),
}: DataTableProps<T>): React.ReactElement {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const isExpandable = columns.some((column) => column.expand);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

  return (
    <section className="py-1">
      <div className="block w-full overflow-x-auto bg-white border border-gray-200 rounded">
        {tableHeader && (
          <h3 className="mx-6 my-2 font-bold text-slate-700">{tableHeader}</h3>
        )}

        <table className="items-center w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 font-semibold text-slate-600 align-middle border-b text-xs uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
              {columns.map((column, index) => (
                <th key={index} className="px-6 py-3">
                  {column.title}
                </th>
              ))}
              {actions && <th className="px-6 py-4"></th>}
            </tr>
          </thead>
          {data.length ? (
            <tbody>
              {data
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item, rowIndex) => (
                  <>
                    <tr
                      key={rowIndex}
                      className={`text-xs ${
                        rowIndex !== data.length - 1 ? "border-b" : ""
                      }`}
                    >
                      {columns.map((column, colIndex) => (
                        <td
                          key={colIndex}
                          className="px-6 whitespace-nowrap p-4"
                        >
                          {column.render(item)}
                        </td>
                      ))}
                      {isExpandable && (
                        <td className="px-6 py-4 hover:cursor-pointer">
                          <button onClick={() => toggleRowExpansion(rowIndex)}>
                            {expandedRow === rowIndex ? "Collapse" : "Expand"}
                          </button>
                        </td>
                      )}
                      {actions && (
                        <td className="px-6 py-4 hover:cursor-pointer">
                          <Popover position="right" withArrow>
                            <Popover.Target>
                              <DotsThreeOutlineVertical
                                size={16}
                                data-testid="dots-icon"
                                weight="fill"
                              />
                            </Popover.Target>
                            <Popover.Dropdown>
                              <div>
                                <ul>
                                  {actions.map((action, actionIndex) => (
                                    <li
                                      key={actionIndex}
                                      className="-mx-2 rounded-md py-1 pl-2 pr-6 text-sm text-gray-800 hover:bg-primary-50 hover:text-primary-100"
                                    >
                                      <button
                                        onClick={() => action.handler(item)}
                                      >
                                        {action.label}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Popover.Dropdown>
                          </Popover>
                        </td>
                      )}
                    </tr>
                    {isExpandable && expandedRow === rowIndex && (
                      <tr className="bg-gray-50">
                        {/* Adjust colSpan to cover all columns including actions column */}
                        <td colSpan={columns.length + (actions ? 1 : 0)}>
                          {columns.find((column) => column.expand)?.expand!(
                            item
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  className="text-center text-gray-500 py-3 text-xs"
                  colSpan={columns.length + (isExpandable ? 1 : 0)}
                >
                  {emptyTableElement}
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <div className="mx-6 mb-3">
          <Pagination
            initialPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </section>
  );
}
