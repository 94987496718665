import { BrokerAccountDetails } from "../types";

interface BalancesOverviewPrpps {
  brokerAccountDetails: BrokerAccountDetails;
  dailyChange: number;
}
export const BalancesOverview = ({
  brokerAccountDetails,
  dailyChange,
}: BalancesOverviewPrpps) => {
  return (
    <article className="bg-white p-6 rounded-lg border border-gray-200 my-3">
      <h3 className="text-gray-700 font-semibold text-lg mb-3">
        Balances Overview
      </h3>

      <div className="flex justify-between">
        <article>
          <p className="font-bold text-sm text-gray-600 mb-2"> Buying Power</p>
          <p className="portfolio-value text-sm text-gray-600">
            $ {brokerAccountDetails.buying_power}
          </p>
        </article>
        <article>
          <p className="font-bold text-sm text-gray-600 mb-2"> Cash </p>
          <p className="portfolio-value  text-sm text-gray-600 ">
            $ {brokerAccountDetails.cash}
          </p>
        </article>
        <article>
          <p className="font-bold text-sm text-gray-600 mb-2"> Equity</p>
          <p className="portfolio-value  text-sm text-gray-600">
            $ {brokerAccountDetails.equity}
          </p>
        </article>
        <article>
          <p className="font-bold text-sm text-gray-600 mb-2"> Daily Change</p>
          <p className="portfolio-value text-sm text-gray-600">
            $ {dailyChange.toFixed(3)}
          </p>
        </article>
      </div>
    </article>
  );
};
