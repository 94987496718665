import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { CreateUserStrategyRequest, Strategy } from "../types";
import notification from "modules/@core/lib/notifications";
import { strategyQueryKeys } from ".";

const createUserStrategy = async (
  strategyDetails: CreateUserStrategyRequest
) => {
  return (await axios.post<Strategy>(`/user-strategy/`, strategyDetails)).data;
};

export const useCreateUserStrategy = (userId: number | null) => {
  const queryClient = useQueryClient();

  return useMutation<
    Strategy,
    Error,
    CreateUserStrategyRequest,
    { previousStrategies?: Strategy[] }
  >({
    mutationFn: async (strategyDetails) => {
      const newStrategy = await createUserStrategy(strategyDetails);

      // Optimistic update: update the query data for 'user-strategies'
      queryClient.setQueryData<Strategy[]>(
        strategyQueryKeys.userStrategies,
        (old) => {
          return old ? [...old, newStrategy] : [newStrategy];
        }
      );

      return newStrategy;
    },

    // When mutation is successful, refetch the 'user-strategies' query
    onSettled: () => {
      notification.notify("User strategy successfully created");
      queryClient.invalidateQueries({ queryKey: strategyQueryKeys.userStrategies });
    },

    // If mutation fails, rollback the optimistic update
    onError: (err, strategyDetails, context) => {
      queryClient.setQueryData(
        strategyQueryKeys.userStrategies,
        context?.previousStrategies
      );
    },
  });
};
