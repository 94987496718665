import { Loader, Modal } from "@mantine/core";
import { PlusCircle } from "@phosphor-icons/react";
import { AddBrokerModal } from "modules/broker/components/AddBrokerModal";
import { BrokerTable } from "modules/broker/components/BrokerTable";
import { useBrokersService } from "modules/broker/providers";
import { useEffect, useState } from "react";
export const Overview = () => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { brokerService, setBrokers, brokers } = useBrokersService();
  useEffect(() => {
    const fetchBrokers = async () => {
      setIsRequestLoading(true);
      try {
        const fetchedBrokers = await brokerService.getBrokers({
          skip: 0,
          limit: 100,
        });
        setBrokers(fetchedBrokers);
      } catch (err) {
        console.error("Error fetching brokers");
        console.error(err);
      }
      setIsRequestLoading(false);
    };

    fetchBrokers();
  }, [brokerService, setBrokers]);
  return (
    <div className="grid grid-cols-2">
      <div>
        {isRequestLoading ? (
          <Loader />
        ) : (
          <div className="bg-white px-5 py-4 rounded w-full border">
            <div className="flex gap-5 items-center my-3">
              <h2 className="text-lg font-bold"> Brokers</h2>
              <button
                onClick={() => setIsModalOpened(true)}
                className="flex items-center border-2 gap-2 border-gray-900 pr-7 pl-2 py-2 rounded hover:bg-gray-100 transition ease-in-out delay-150"
              >
                <PlusCircle size={20} weight="fill" />
                <p className="text-sm">Add Broker</p>
              </button>
            </div>
            <BrokerTable brokers={brokers} />
            <Modal
              opened={isModalOpened}
              onClose={() => setIsModalOpened(false)}
              centered={true}
              size="auto"
              withCloseButton={false}
            >
              <AddBrokerModal />
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};
