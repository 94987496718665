import { RouteObject } from "react-router-dom";
import { LoginPage } from "modules/auth/pages/LoginPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { RecoverPasswordPage } from "../pages/RecoverPasswordPage";
import { AcceptInvitePage } from "../pages/AcceptInvitePage";

export const authRoutes: RouteObject[] = [
  {
    path: "auth/*",
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "recover-password",
        element: <RecoverPasswordPage />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "register",
        element: <AcceptInvitePage />,
      },
    ],
  },
];
