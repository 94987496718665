import * as Yup from 'yup';

/**
 * `ForgotPasswordSchema` defines the validation rules for the login form.
 *
 * Fields:
 * - `email`: A string that should be a valid email address and is required.
 *
 * @example
 * const isValid = await ForgotPasswordSchema.isValid({
 *   email: 'user@example.com',
 * });
 * // Returns true if valid, false otherwise
 *
 */
const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});

export default ForgotPasswordSchema;