export enum StrategyType {
  MOVING_AVERAGE_CROSSOVER = "MovingAverageCrossover",
  BOLLINGER_BANDS = "BollingerBandsStrategy",
  EXPONENTIAL_MOVING_AVERAGE = "EMABasedStrategy",
}
export interface Strategy {
  id: number;
  name: string;
  description: string;
  type: StrategyType;
  params: StrategyParameters;
}

export interface MovingAverageStrategyParams {
  fast_period: number;
  slow_period: number;
}

export interface BollingerBandsStrategyParams {
  window: number;
  std: number;
}

export interface EMABasedStrategyParams {
  ema_period: number;
}

export interface GetStrategiesRequest {
  /** The number of items to skip in the list. */
  skip: number;

  /** The maximum number of items to retrieve. */
  limit: number;
}

export interface StrategyParameters {
  [key: string]: number;
}

export interface CreateUserStrategyRequest {
  user_id: string;
  strategy_id: string;
  broker_id: string;
  parameters: StrategyParameters;
  symbol: string;
  timeframe: string;
  risk_per_trade: string;
}

export interface UserStrategy {
  id: number;
  user_id: number;
  strategy_id: number;
  broker_id: number;
  parameters: StrategyParameters;
  symbol: string;
  timeframe: string;
  risk_per_trade: number;
}

export interface StrategyStatus {
  strategy_id: number;
  status: string;
  start_time: Date;
  stop_time: Date;
}


export enum UserStrategyStatus {
  STOPPED = 'STOPPED',
  RUNNING = "RUNNING"
}