import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useOpenPositions } from "../hooks/useOpenPositions";
import { AssetType, PositionSideType } from "modules/@core/types/assets";
import { BrokerPosition } from "../types";
import { Cell, Legend, Pie, PieChart, Tooltip, TooltipProps } from "recharts";
import { BrokerPositionsTable } from "../components/BrokerPositionsTable";

// type Aggregation = {
//     [key in AssetType | PositionSideType]: number;
//   };

type AssetTypeAggregation = {
  [key in AssetType]: number;
};

type SideAggregation = {
  [key in PositionSideType]: number;
};

const COLORS = ["#FFBB28", "#00C49F", "#FF8042"];

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "5px",
          border: "1px solid #ddd",
        }}
      >
        <ul className="list-none">
          {payload?.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{ color: COLORS[index % COLORS.length] }}
            >
              {`${entry.name}: ${
                entry.value && entry.value > 0 ? `$${entry.value}` : "-"
              }`}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

export const ViewPositions = () => {
  const { brokerId } = useParams();
  const numericBrokerId = brokerId ? parseInt(brokerId, 10) : undefined;
  const {
    data: openPositions,
    isLoading,
    error,
  } = useOpenPositions(numericBrokerId);

  // Function to safely parse the numeric strings to numbers
  const parseToNumber = (value: string | null) => {
    return value ? parseFloat(value) : 0;
  };

  // Function to aggregate the data
  const aggregateData = (positions?: BrokerPosition[]) => {
    // Initialize aggregation objects with all categories set to 0
    const assetClassAggregation: AssetTypeAggregation = {
      [AssetType.EQUITY]: 0,
      [AssetType.CRYPTO]: 0,
      [AssetType.CASH]: 0,
    };

    const positionSideAggregation: SideAggregation = {
      [PositionSideType.LONG]: 0,
      [PositionSideType.SHORT]: 0,
    };

    if (positions) {
      positions.forEach((position) => {
        const marketValue = parseToNumber(position.market_value);
        assetClassAggregation[position.asset_class as AssetType] += marketValue;
        positionSideAggregation[position.side as PositionSideType] +=
          marketValue;
      });
    }

    const assetClassDataForChart = Object.entries(assetClassAggregation).map(
      ([key, value]) => ({
        name: key === AssetType.EQUITY ? "Stocks" : key,
        value,
      })
    );

    const positionSideDataForChart = Object.entries(
      positionSideAggregation
    ).map(([key, value]) => ({
      name: key,
      value,
    }));

    return { assetClassDataForChart, positionSideDataForChart };
  };

  // Use useMemo to avoid recalculating on every render
  const { assetClassDataForChart, positionSideDataForChart } = useMemo(() => {
    return aggregateData(openPositions);
  }, [openPositions]);

  // Chart size configuration
  const chartSize = {
    width: 300,
    height: 300,
    innerRadius: 40,
    outerRadius: 60,
  };

  // if (isLoading) {
  //   <div>Loading positions...</div>;
  // } else if (error) {
  //   <div>Error: {error.message}</div>;
  // } else {
  return (
    <div>
      <h1 className="text-3xl font-bold">Positions</h1>
      <p className="text-sm text-gray-700">
        View and manage your {openPositions?.length} positions.
      </p>

      <div className="flex justify-around">
        <PieChart width={chartSize.width} height={chartSize.height}>
          <Pie
            data={assetClassDataForChart}
            cx={chartSize.width / 2}
            cy={chartSize.height / 2}
            innerRadius={chartSize.innerRadius}
            outerRadius={chartSize.outerRadius}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {assetClassDataForChart.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            iconType="circle"
            layout="vertical"
            align="right"
            verticalAlign="middle"
          />
        </PieChart>

        <PieChart width={chartSize.width} height={chartSize.height}>
          <Pie
            data={positionSideDataForChart}
            cx={chartSize.width / 2}
            cy={chartSize.height / 2}
            innerRadius={chartSize.innerRadius}
            outerRadius={chartSize.outerRadius}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {positionSideDataForChart.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            iconType="circle"
            layout="vertical"
            align="right"
            verticalAlign="middle"
          />
        </PieChart>
      </div>

      <BrokerPositionsTable positions={openPositions ?? []} />
    </div>
  );
};
