import { TextInput, TextInputProps } from "@mantine/core";

type InputFieldProps = TextInputProps & React.RefAttributes<HTMLInputElement>;

export const InputField = ({ ...props }: InputFieldProps) => {
  return (
    <TextInput
      styles={{
        label: {
          fontSize: "14px",
          margin: "5px 0px",
          fontWeight: "600",
          color: "#414141",
        },
      }}
      {...props}
    />
  );
};
