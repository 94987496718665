import { InputField } from "modules/@core/components/InputField";
import { useFormik } from "formik";
import { useState } from "react";
import notification from "modules/@core/lib/notifications";
import { useAuth } from "../providers";
import Logo from "assets/icons/logo.png";
import { Button } from "modules/@core/components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import ResetPasswordSchema from "../models/resetPasswordSchema";
import { PasswordResetDTO } from "../types";

interface FormValues {
  new_password: string;
  confirm_password: string;
}

export const ResetPasswordForm = () => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromURL = queryParams.get("token");

  const initialValues: FormValues = {
    new_password: "",
    confirm_password: "",
  };

  const { authService } = useAuth();

  const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<FormValues>({
      initialValues: initialValues,
      validationSchema: ResetPasswordSchema,
      onSubmit: (values) => {
        resetPassword(values);
      },
    });

  const resetPassword = async (formValues: FormValues) => {
    setIsRequestLoading(true);
    if (tokenFromURL) {
      const credentials: PasswordResetDTO = {
        token: tokenFromURL,
        new_password: formValues.new_password,
      };
      try {
        await authService.resetPassword(credentials);
        notification.notify(
          "Password Successfully reset. Please log in with your new credentials"
        );

        navigate("/auth/login");
      } catch (err) {
        notification.notifyError("Unable to recover password");
        console.log(err);
        setIsRequestLoading(false);
      } finally {
        setIsRequestLoading(false);
      }
    }
  };

  return (
    <div className="py-7 px-7 w-full">
      <img src={Logo} alt="Logo" className="h-12 mb-5" />
      <h3 className="font-bold text-lg my-2"> Reset Password </h3>
      <p className="text-sm">
        Please enter the token you were provided and the new password you would
        like for your account
      </p>
      <form onSubmit={handleSubmit}>
        <InputField
          label="New Password"
          name="new_password"
          type="password"
          className="my-3"
          placeholder="Your new password"
          value={values.new_password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.new_password && errors.new_password
              ? errors.new_password
              : undefined
          }
        />

        <InputField
          label="Confirm New Password"
          name="confirm_password"
          type="password"
          className="my-3"
          placeholder="Confirm your new password"
          value={values.confirm_password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.confirm_password && errors.confirm_password
              ? errors.confirm_password
              : undefined
          }
        />

        <div className="flex align-center gap-3">
          <Button
            type="submit"
            loading={isRequestLoading}
            className="mt-5"
            disabled={isRequestLoading}
          >
            {isRequestLoading ? "Loading..." : "Reset Password"}
          </Button>
        </div>
      </form>
    </div>
  );
};
