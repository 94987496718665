import React from "react";
import type { Broker } from "../types";
import {
  DataTable,
  TableColumn,
  TableAction,
} from "modules/@core/components/DataTable";
import notification from "modules/@core/lib/notifications";
import { useNavigate } from "react-router-dom";
import { BrokerIcon } from "./BrokerIcon";
import { Badge } from "@mantine/core";
import { useDisconnectBroker } from "../hooks/useDisconnectBroker";
import { useConnectBroker } from "../hooks/useConnectBroker";
interface BrokerTableProps {
  brokers: Broker[];
}

export const BrokerTable: React.FC<BrokerTableProps> = ({ brokers }) => {
  const navigate = useNavigate();
  const { mutate: connectBroker } = useConnectBroker();
  const { mutate: disconnectBroker } = useDisconnectBroker();

  // Column definitions
  const columns: TableColumn<Broker>[] = [
    {
      title: "Broker",
      render: (broker: Broker) => (
        <div
          className="flex items-center gap-5 underline hover:cursor-pointer"
          onClick={() => {
            navigate(`/dashboard/brokers/${broker.id}`);
          }}
        >
          <BrokerIcon brokerName={broker.broker_name} />
          {broker.broker_name}
        </div>
      ),
    },
    { title: "Type", render: (broker: Broker) => broker.account_type },
    { title: "Nickname", render: (broker: Broker) => broker.nick_name },
    {
      title: "Status",
      render: (broker: Broker) => (
        <Badge variant="dot" color={broker.connection_status ? "Green" : "red"}>
          {broker.connection_status ? "Online" : "Offline"}
        </Badge>
      ),
    },
  ];

  const onBrokerDelete = (broker: Broker) => {};

  const onBrokerEdit = (broker: Broker) => {};

  const onBrokerConnect = (broker: Broker) => {
    connectBroker(broker.id, {
      onSuccess: () => {
        notification.notify("Broker Successfully Connected");
      },
      onError: () => {
        notification.notifyError("Unable to connect broker");
      },
    });
  };

  const onBrokerDisconnect = (broker: Broker) => {
    disconnectBroker(broker.id, {
      onSuccess: () => {
        notification.notify("Broker Successfully Disconnected");
      },
      onError: () => {
        notification.notifyError("Unable to disconnect broker");
      },
    });
  };


  const onBrokerView = async (broker: Broker) => {
    navigate(`/dashboard/brokers/${broker.id}`);
  };

  const brokerActions: TableAction<Broker>[] = [
    {
      label: "View Broker",
      handler: onBrokerView,
    },
    {
      label: "Edit Broker",
      handler: onBrokerEdit,
    },
    {
      label: "Delete Broker",
      handler: onBrokerDelete,
    },
    {
      label: "Connect Broker",
      handler: onBrokerConnect,
    },
    {
      label: "Disconnect Broker",
      handler: onBrokerDisconnect,
    },
  ];

  return (
    <DataTable
      data={brokers}
      columns={columns}
      actions={brokerActions}
      itemsPerPage={4} // Set the number of items per page
    />
  );
};
