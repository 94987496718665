import React, { useContext, useState } from "react";
import AuthenticationService from "modules/auth/services";
import { axios } from "modules/@core/lib/axios";
import storage from "modules/@core/lib/storage";

interface AuthContextType {
  token: string | null;
  authService: AuthenticationService;
  setToken: (token: string) => void;
  isAuthenticated: boolean;
}

interface Props {
  children: React.ReactNode;
}

const authServiceInstance = new AuthenticationService(axios);

const AuthContext = React.createContext<AuthContextType>({
  authService: authServiceInstance,
  token: "",
  setToken: (token) => {},
  isAuthenticated: false,
});

export const AuthProvider = ({ children }: Props) => {
  const [token, setTokenState] = useState(storage.getToken());

  const setToken = (newToken: string) => {
    storage.setToken(newToken);
    setTokenState(newToken);
  };

  const isAuthenticated = token !== null;

  return (
    <AuthContext.Provider
      value={{
        authService: authServiceInstance,
        token,
        setToken,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }
  return context;
}
