import AlpacaBrokersLogo from "assets/icons/alpaca_logo.png";
import IGBrokersLogo from "assets/icons/ig_brokers.jpg";
import { SupportedBroker } from "../types";

interface BrokerIconProps {
  brokerName: SupportedBroker;
}
export const BrokerIcon = ({ brokerName }: BrokerIconProps) => {
  switch (brokerName) {
    case SupportedBroker.ALPACA:
      return (
        <img
          src={AlpacaBrokersLogo}
          alt="Alpaca Brokers Logo"
          className="h-5"
        />
      );

    case SupportedBroker.IGTRADING:
      return <img src={IGBrokersLogo} alt="IG Brokers Logo" className="h-5" />;
    default:
      return <span></span>;
  }
};
