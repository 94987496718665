import { Handshake } from "@phosphor-icons/react";
import { AccountType, BrokerCreate, SupportedBroker } from "../types";
import { InputField } from "modules/@core/components/InputField";
import { useFormik } from "formik";
import AddBrokerSchema from "../models/addBroker";
import { useBrokersService } from "../providers";
import { useState } from "react";
import notification from "modules/@core/lib/notifications";
import { Select } from "@mantine/core";
import { Button } from "modules/@core/components/Button";
import { useUserService } from "modules/user/providers";

export const AddBrokerModal = () => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const supportedBrokers = Object.values(SupportedBroker);
  const supportedAccountTypes = Object.values(AccountType);

  const { user } = useUserService();
  const { brokerService, setBrokers } = useBrokersService();

  const initialValues: BrokerCreate = {
    broker_name: "" as SupportedBroker,
    account_type: "" as AccountType,
    nick_name: "",
    api_key: "",
    secret_key: "",
    connection_status: true,
    username: "",
    password: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik<BrokerCreate>({
    initialValues: initialValues,
    validationSchema: AddBrokerSchema,
    onSubmit: (values) => {
      console.log("Attempting to submit");
      createBroker(user?.id!, values);
    },
  });

  const createBroker = async (userId: number, crendentials: BrokerCreate) => {
    setIsRequestLoading(true);
    try {
      await brokerService.createBroker(userId, crendentials);
      notification.notify("Broker Successfully Created");

      // Fetch and set new brokers
      const fetchedBrokers = await brokerService.getBrokers({
        skip: 0,
        limit: 100,
      });
      setBrokers(fetchedBrokers);
    } catch (err) {
      notification.notifyError("Unable to add Broker");
      console.log(err);
      setIsRequestLoading(false);
    } finally {
      setIsRequestLoading(false);
    }
  };

  return (
    <div>
      <Handshake size={32} weight="fill" className="text-primary-500" />
      <h3 className="font-bold text-lg my-2">Add Broker</h3>
      <p>
        Enter the details for the broker you would like to add to your account
        below
      </p>
      <form onSubmit={handleSubmit}>
        {/* Broker Name Select */}
        <Select
          label="Broker Name"
          placeholder="Select your Broker"
          data={supportedBrokers}
          value={values.broker_name}
          onChange={(value) => setFieldValue("broker_name", value)}
          onBlur={handleBlur}
          error={
            touched.broker_name && errors.broker_name
              ? errors.broker_name
              : undefined
          }
        />

        {/* Account Type Select */}
        <Select
          label="Account Type"
          placeholder="Choose your account type"
          data={supportedAccountTypes}
          value={values.account_type}
          onChange={(value) => setFieldValue("account_type", value)}
          onBlur={handleBlur}
          error={
            touched.account_type && errors.account_type
              ? errors.account_type
              : undefined
          }
        />

        {/* Other fields */}
        <InputField
          label="Nickname"
          name="nick_name"
          value={values.nick_name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.nick_name && errors.nick_name ? errors.nick_name : undefined
          }
        />
        <InputField
          label="API Key"
          name="api_key"
          type="password"
          value={values.api_key}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.api_key && errors.api_key ? errors.api_key : undefined}
        />

        {values.broker_name === SupportedBroker.ALPACA && (
          <InputField
            label="Secret Key"
            name="secret_key"
            type="password"
            value={values.secret_key}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.secret_key && errors.secret_key
                ? errors.secret_key
                : undefined
            }
          />
        )}

        {values.broker_name === SupportedBroker.IGTRADING && (
          <div>
            <InputField
              label="Username"
              name="username"
              type="text"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.username && errors.username
                  ? errors.username
                  : undefined
              }
            />
            <InputField
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.password && errors.password
                  ? errors.password
                  : undefined
              }
            />
          </div>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          className="mt-5"
          disabled={isRequestLoading}
          loading={isRequestLoading}
          onClick={() => {
            console.log(values);
            console.log(errors);
          }}
        >
          {isRequestLoading ? "Creating..." : "Add Broker"}
        </Button>
      </form>
    </div>
  );
};
