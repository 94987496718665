import { User } from "modules/user/types";

const STORAGE_PREFIX = 'quants_edge_';

const storage = {
    getToken: () => {
        const token = localStorage.getItem(`${STORAGE_PREFIX}token`);
        return token;
    },

    setToken: (token: string) => {
        localStorage.setItem(`${STORAGE_PREFIX}token`, token);
    },

    removeToken: () => {
        localStorage.removeItem(`${STORAGE_PREFIX}token`);
    },

    getUser: () => {
        const localStorageUser = localStorage.getItem(`${STORAGE_PREFIX}_user`)
        if (localStorageUser) {
            const user = JSON.parse(localStorageUser)
            return user as User;
        }

        return null

    },

    setUser: (user: User) => {
        localStorage.setItem(`${STORAGE_PREFIX}_user`, JSON.stringify(user))
    }
}

export default storage;
