import { RouteObject } from "react-router-dom";
import { ViewBroker } from "../pages/ViewBroker";
import { BrokerDashboard } from "../pages/BrokerDashboard";
import { ViewPositions } from "../pages/ViewPositions";

export const brokerRoutes: RouteObject[] = [
  {
    path: "brokers/*",
    children: [
      {
        path: "",
        element: <BrokerDashboard />,
      },
      {
        path: ":brokerId",
        element: <ViewBroker />,
      },
      {
        path: "positions/:brokerId",
        element: <ViewPositions />,
      },
    ],
  },
];
