import * as Yup from 'yup';

/**
 * `LoginSchema` defines the validation rules for the login form.
 *
 * Fields:
 * - `username`: A string that should be a valid email address and is required.
 * - `password`: A string that should be at least 8 characters long and is required.
 *
 * @example
 * const isValid = await LoginSchema.isValid({
 *   username: 'user@example.com',
 *   password: 'password123'
 * });
 * // Returns true if valid, false otherwise
 *
 */
const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
});

export default LoginSchema;