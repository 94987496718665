import { PaperPlaneTilt } from "@phosphor-icons/react";
import { InputField } from "modules/@core/components/InputField";
import { useFormik } from "formik";
import { useState } from "react";
import notification from "modules/@core/lib/notifications";
import { Select } from "@mantine/core";
import { Button } from "modules/@core/components/Button";
import { useUserService } from "modules/user/providers";
import { InviteUserRequest, Role } from "../types";
import { InviteUserSchema } from "../models/inviteUser";

export const InviteUserModal = () => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { userService, setUserInvites } = useUserService();

  const initialValues: InviteUserRequest = {
    email: "",
    role_name: Role.TRADER,
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik<InviteUserRequest>({
    initialValues: initialValues,
    validationSchema: InviteUserSchema,
    onSubmit: (values) => {
      console.log("Attempting to submit");
      inviteUser(values);
    },
  });

  const inviteUser = async (credentials: InviteUserRequest) => {
    setIsRequestLoading(true);
    try {
      await userService.inviteUser(credentials);
      notification.notify("User Successfully Invited");

      const fetchedUserInvitations = await userService.getAllInvitations();
      setUserInvites(fetchedUserInvitations);
    } catch (err) {
      notification.notifyError("Unable to Invite User");
      console.log(err);
      setIsRequestLoading(false);
    } finally {
      setIsRequestLoading(false);
    }
  };

  return (
    <div>
      <PaperPlaneTilt size={32} weight="fill" className="text-primary-500" />
      <h3 className="font-bold text-lg my-2">Invite User</h3>
      <p>Enter the details for the user you would like to invite below</p>
      <form onSubmit={handleSubmit}>
        {/* Other fields */}
        <InputField
          label="Email Address"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email ? errors.email : undefined}
        />

        <Select
          label="Account Type"
          placeholder="Choose your account type"
          data={Object.values(Role)}
          value={values.role_name}
          onChange={(value) => setFieldValue("role_name", value)}
          onBlur={handleBlur}
          error={
            touched.role_name && errors.role_name ? errors.role_name : undefined
          }
        />

        {/* Submit Button */}
        <Button
          type="submit"
          className="mt-5"
          disabled={isRequestLoading}
          loading={isRequestLoading}
          onClick={() => {
            console.log(values);
            console.log(errors);
          }}
        >
          {isRequestLoading ? "Inviting User..." : "Invite User"}
        </Button>
      </form>
    </div>
  );
};
