import { Loader, Modal } from "@mantine/core";
import { useState } from "react";
import { UsersTable } from "../components/UsersTable";
import { PlusCircle } from "@phosphor-icons/react";
import { Button } from "modules/@core/components/Button";
import { InviteUserModal } from "../components/InviteUserModal";
import { UserInvitesTable } from "../components/UserInvitesTable";
import { useGetUsers } from "../hooks/useGetUsers";
import { useGetUserInvites } from "../hooks/useGetUserInvites";

export const UserDashboard = () => {
  const { data: userInvites, isLoading: isLoadingInvites } =
    useGetUserInvites();
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <div className="">
      <div className="flex justify-between gap-5 items-center my-3">
        <div>
          <h2 className="text-lg font-bold">User Management</h2>
          <p>
            {" "}
            Here you can manage all aspects surrounding users in the system{" "}
          </p>
        </div>
        <Button
          variant="secondary"
          onClick={() => setIsModalOpened(true)}
          icon={<PlusCircle size={20} weight="fill" />}
        >
          Invite User
        </Button>
      </div>
      <div>
        {isLoadingUsers ? <Loader /> : <UsersTable users={users ?? []} />}
      </div>
      <div className="my-5">
        {isLoadingInvites ? (
          <Loader />
        ) : (
          <UserInvitesTable invites={userInvites ?? []} />
        )}
      </div>
      <Modal
        opened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        centered={true}
        size="auto"
        withCloseButton={false}
      >
        <InviteUserModal />
      </Modal>
    </div>
  );
};
