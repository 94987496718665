import * as Yup from 'yup';
import { AccountType, SupportedBroker } from '../types';

/**
 * `AddBrokerSchema` defines the validation rules for the form used to add a new broker.
 *
 * Fields:
 * - `user_id`: A string that represents the user's ID and is required.
 * - `broker_name`: An enum value of `SupportedBroker` that is required.
 * - `account_type`: An enum value of `AccountType` that is required.
 * - `nick_name`: A string that represents the nickname of the broker account; it is required and must be 50 characters or fewer.
 * - `api_key`: A string that represents the API key for the broker; it is conditionally required if the account type is 'Live'.
 * - `secret_key`: A string that represents the secret key for the broker; it is conditionally required if the account type is 'Live'.
 * - `connection_status`: A boolean that indicates the connection status; it is required.
 * - `other_credentials`: An object containing any other necessary credentials; all contained values are required.
 * - `username`: A string that represents the username; it is optional.
 * - `password`: A string that represents the password; it is optional.
 *
 * The schema also uses conditional logic to require the `api_key` and `secret_key` fields only if the `account_type` is set to 'Live'.
 *
 * @example
 * const isValid = await AddBrokerSchema.isValid({
 *   user_id: '12345',
 *   broker_name: SupportedBroker.ALPACA,
 *   account_type: AccountType.LIVE,
 *   nick_name: 'My Alpaca Account',
 *   api_key: 'api_key_here',
 *   secret_key: 'secret_key_here',
 *   connection_status: true,
 *   other_credentials: {
 *     some_credential: 'value'
 *   },
 *   username: 'myusername',
 *   password: 'mypassword',
 * });
 * // Returns true if valid, false otherwise
 */
const AddBrokerSchema = Yup.object().shape({
    broker_name: Yup.mixed().oneOf(Object.values(SupportedBroker), 'Invalid broker name').required('Broker name is required'),
    account_type: Yup.mixed().oneOf(Object.values(AccountType), 'Invalid account type').required('Account type is required'),
    nick_name: Yup.string().required('Nickname is required').max(50, 'Nickname must be at most 50 characters long'),
    api_key: Yup.string(),
    secret_key: Yup.string(),
    connection_status: Yup.boolean().required('Connection status is required'),

    username: Yup.string().notRequired(),
    password: Yup.string().notRequired(),
});

export default AddBrokerSchema;
