import React, { createContext, useContext, useState } from "react";
import UserService from "modules/user/services";
import { User, UserInvitation } from "modules/user/types";
import storage from "modules/@core/lib/storage";

/**
 * Props for the UserContextProvider component.
 * @property {React.ReactNode} children - The components that will be rendered within the UserContextProvider.
 * @property {UserService} userService - An instance of UserService for making user-related API calls.
 */
interface Props {
  children: React.ReactNode;
  userService: UserService;
}

/**
 * The shape of the user context.
 * @property {User | undefined} user - The current user, or undefined if no user is logged in.
 * @property {(user: User) => void} setUser - A function to update the current user.
 * @property {UserService} userService - An instance of UserService for making user-related API calls.
 */
interface UserContextType {
  user: User | null;
  setUser: (user: User) => void;
  userService: UserService;
  users: User[];
  setUsers: (users: User[]) => void;
  userInvites: UserInvitation[];
  setUserInvites: (userInvites: UserInvitation[]) => void;
}

/**
 * Create a new context for user data and services, with an undefined default value.
 */
const UserContext = createContext<UserContextType | undefined>(undefined);

/**
 * A component to provide the user context to its descendants.
 * @param {Props} props - The props for the component.
 */
export const UserContextProvider = ({ children, userService }: Props) => {
  const [user, setUserState] = useState(storage.getUser());
  const [users, setUsers] = useState<User[]>([]);
  const [userInvites, setUserInvites] = useState<UserInvitation[]>([]);

  const setUser = (newUser: User) => {
    storage.setUser(newUser);
    setUserState(newUser);
  };
  return (
    <UserContext.Provider
      value={{
        userService,
        user,
        setUser,
        users,
        setUsers,
        userInvites,
        setUserInvites,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

/**
 * A custom hook to provide access to the user context.
 * @returns {UserContextType} The user context.
 * @throws Will throw an error if used outside of a UserContextProvider.
 */
export function useUserService(): UserContextType {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      "useUserService must be used within a UserContextProvider."
    );
  }
  return context;
}
