import React from "react";

export type BadgeColor =
  | "default"
  | "dark"
  | "red"
  | "green"
  | "yellow"
  | "indigo"
  | "purple"
  | "pink";

interface BadgeProps {
  color: BadgeColor;
  children: React.ReactNode;
}

const colorClasses = {
  default: "bg-blue-100 text-blue-800 border-blue-400",
  dark: "bg-gray-100 text-gray-800 border-gray-500",
  red: "bg-red-100 text-red-800 border-red-400",
  green: "bg-green-100 text-green-800 border-green-400",
  yellow: "bg-yellow-100 text-yellow-800 border-yellow-300",
  indigo: "bg-indigo-100 text-indigo-800 border-indigo-400",
  purple: "bg-purple-100 text-purple-800 border-purple-400",
  pink: "bg-pink-100 text-pink-800 border-pink-400",
};

export const Badge: React.FC<BadgeProps> = ({ color, children }) => {
  return (
    <span
      className={`text-xs font-bold me-2 px-3 py-1 rounded-full ${colorClasses[color]}`}
    >
      {children}
    </span>
  );
};
