/* eslint-disable react/jsx-no-undef */
import { Divider, Menu } from "@mantine/core";
import { Avatar } from "@mantine/core";
import Logo from "assets/icons/logo.png";
import storage from "modules/@core/lib/storage";
import { useBrokersService } from "modules/broker/providers";
import { useUserService } from "modules/user/providers";
import { useNavigate } from "react-router-dom";
export const Header = () => {
  const { user } = useUserService();
  const { brokers } = useBrokersService();
  const navigate = useNavigate();

  const handleLogout = () => {
    storage.removeToken();
    navigate("/auth/login");
  };

  return (
    <header className="py-5 px-5 bg-white flex justify-between items-center border-b border-gray-200">
      <div>
        <img src={Logo} alt="Logo" className="h-10" />
      </div>
      <div className="flex justify-around">
        <article>
          <h4 className="text-sm text-gray-400 font-semibold">Balance</h4>
          <p className="text-sm font-semibold text-gray-700">$1,500,000</p>
        </article>
        <Divider className="mx-12" orientation="vertical" />

        <article>
          <h4 className="text-sm text-gray-400 font-semibold">Equity</h4>
          <p className="text-sm font-semibold text-gray-700">$1,500,000</p>
        </article>
        <Divider className="mx-12" orientation="vertical" />
        <article>
          <h4 className="text-sm text-gray-400 font-semibold">
            Trades in Progress
          </h4>
          <p className="text-sm font-semibold text-gray-700">$1,500,000</p>
        </article>
        <Divider className="mx-12" orientation="vertical" />
        <article>
          <h4 className="text-sm text-gray-400 font-semibold">
            Available Brokers
          </h4>
          <p className="text-sm font-semibold text-gray-800">
            {" "}
            {brokers.length}
          </p>
        </article>
      </div>
      <div>
        <Menu position="bottom" withArrow>
          <Menu.Target>
            <Avatar color="blue" className="cursor-pointer" radius="xl">
              {user?.full_name.charAt(0)}
            </Avatar>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </header>
  );
};
