import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { UserStrategy } from "../types";
import { strategyQueryKeys } from ".";

const getUserStrategies = async (userId?: number) => {
  return (await axios.get(`/user-strategies/?user_id=${userId}`)).data;
};

export const useGetUserStrategies = (userId?: number) => {
  return useQuery<UserStrategy[], Error>({
    queryKey: [...strategyQueryKeys.userStrategies, userId],
    queryFn: () => getUserStrategies(userId),
    enabled: userId !== undefined,
  });
};
