import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBrokersService } from "../providers";
import { Broker, BrokerAccountDetails } from "../types";
import { Badge, Loader } from "@mantine/core";
import { PortfolioOverview } from "../components/PortfolioOverview";
import { BrokerConfigurationOverview } from "../components/BrokerConfiguration";
import { BalancesOverview } from "../components/BalancesOverview";
import { PositionsOverview } from "../components/PositionsOverview";
import { useGetBroker } from "../hooks/useGetBroker";
import { useGetBrokerDetails } from "../hooks/useGetBrokerDetails";
import { useGetDailyChange } from "../hooks/useGetDailyChange";

export const ViewBroker = () => {
  const { brokerId } = useParams();
  const brokerIdNumber = brokerId ? parseInt(brokerId) : null;

  // Hooks for fetching data
  const { data: broker, isLoading: isBrokerLoading } =
    useGetBroker(brokerIdNumber);
  const { data: brokerAccountDetails, isLoading: isBrokerDetailsLoading } =
    useGetBrokerDetails(brokerIdNumber);
  const { data: brokerDailyChange, isLoading: isBrokerDailyChangeLoading } =
    useGetDailyChange(brokerIdNumber);

  const chartData = [
    { month: "Jan", portfolioValue: 0 },
    { month: "Feb", portfolioValue: 0 },
    { month: "Mar", portfolioValue: 22000 },
    { month: "Apr", portfolioValue: 22500 },
    { month: "May", portfolioValue: 23000 },
    { month: "June", portfolioValue: 30000 },
  ];

  return (
    <main>
      {isBrokerLoading ? (
        <Loader /> // Individual loader for broker data
      ) : broker ? (
        <div>
          <header className="mt-3 mb-5">
            <div className="flex items-center gap-6">
              <h1 className="text-3xl font-bold">{broker.nick_name}</h1>
              {broker.connection_status ? (
                <Badge color="Green">Online</Badge>
              ) : (
                <Badge color="red">Offline</Badge>
              )}
            </div>
            <p className="text-gray-500">
              Here you can see an overview of your broker account
            </p>
          </header>
          <div className="grid grid-cols-2 gap-4">
            <section>
              {isBrokerDetailsLoading || isBrokerDailyChangeLoading ? (
                <Loader />
              ) : brokerAccountDetails && brokerDailyChange ? (
                <div>
                  <PortfolioOverview
                    dailyChange={brokerDailyChange.daily_change}
                    portfolioValue={parseFloat(
                      brokerAccountDetails.portfolio_value
                    )}
                    data={chartData}
                  />
                  <BalancesOverview
                    dailyChange={brokerDailyChange.daily_change}
                    brokerAccountDetails={brokerAccountDetails}
                  />
                </div>
              ) : null}
            </section>
            <section>
              {brokerAccountDetails ? (
                <BrokerConfigurationOverview
                  brokerAccountDetails={brokerAccountDetails}
                />
              ) : (
                <Loader />
              )}
              <PositionsOverview brokerId={broker.id} />
            </section>
          </div>
        </div>
      ) : null}
    </main>
  );
};
