export const getFormattedDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    // Replace 'en-US' with your locale if different
    return date.toLocaleString('en-US', options).replace(',', '').replace(' AM', 'AM').replace(' PM', 'PM');
}