import React from "react";
import { Button } from "./Button";
import { Warning } from "@phosphor-icons/react";

interface ConfirmationDialogProps {
  title: string;
  description: string;
  confirmText: string;
  isPending: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  description,
  confirmText,
  isPending,
  onConfirm,
  onCancel,
}) => {
  return (
    <div>
      <div>
        <Warning size={32} className="text-primary-500" />
        <h3 className="font-bold text-lg my-2">{title}</h3>
        <p>{description}</p>
        <div className="flex gap-2 my-2">
          <Button
            className="border-1 border-gray-400"
            variant="tertiary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
