import React, { createContext, useContext, useState } from "react";
import StrategyService from "modules/strategy/services";
import { Strategy } from "../types";

interface Props {
  children: React.ReactNode;
  strategyService: StrategyService;
}

interface StrategiesContextType {
  strategies: Strategy[];
  setStrategies: (strategies: Strategy[]) => void;
  strategyService: StrategyService;
}

/**
 * Create a new context for user data and services, with an undefined default value.
 */
const StrategiesContext = createContext<StrategiesContextType | undefined>(
  undefined
);

/**
 * A component to provide the strategies context to its descendants.
 * @param {Props} props - The props for the component.
 */
export const StrategiesContextProvider = ({
  children,
  strategyService,
}: Props) => {
  const [strategies, setStrategies] = useState<Strategy[]>([]); // The current user, managed as state.

  return (
    <StrategiesContext.Provider
      value={{ strategyService, strategies, setStrategies }}
    >
      {children}
    </StrategiesContext.Provider>
  );
};

export function useStrategiesService(): StrategiesContextType {
  const context = useContext(StrategiesContext);
  if (!context) {
    throw new Error(
      "useStrategiesService must be used within a StrategiesContextProvider."
    );
  }
  return context;
}
