import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { User } from "../types";
import { userQueryKeys } from ".";

const getCurrentUserData = async () => {
  return (await axios.get<User>("/user/user/me")).data;
};

export const useGetCurrentUser = () => {
  return useQuery<User, Error>({
    queryKey: userQueryKeys.currentUser,
    queryFn: getCurrentUserData,
  });
};
