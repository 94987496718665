import { Switch } from "@mantine/core";
import { BrokerAccountDetails } from "../types";

interface BrokerConfigurationProps {
  brokerAccountDetails: BrokerAccountDetails;
}
export const BrokerConfigurationOverview = ({
  brokerAccountDetails,
}: BrokerConfigurationProps) => {
  return (
    <article className="mx-5 bg-white p-6 rounded-lg border border-gray-200 mb-2 ">
      <h5 className="text-gray-700 font-semibold text-lg">
        Broker Configuration
      </h5>
      <div className="grid grid-cols-2">
        <div>
          <p className="font-semibold text-gray-600 mt-2">Trading Blocked</p>
          <Switch
            className="my-2"
            checked={brokerAccountDetails.trading_blocked}
          />
        </div>
        <div>
          <p className="font-semibold text-gray-600 mt-2">Account Suspended</p>
          <Switch
            className="my-2"
            checked={brokerAccountDetails.account_blocked}
          />
        </div>
        <div>
          <p className="font-semibold text-gray-600 mt-2">Transfers Blocked</p>
          <Switch
            className="my-2"
            checked={brokerAccountDetails.transfers_blocked}
          />
        </div>
        <div>
          <p className="font-semibold text-gray-600 mt-2">Shorting Enabled</p>
          <Switch
            className="my-2"
            checked={brokerAccountDetails.shorting_enabled}
          />
        </div>
        <div>
          <p className="font-semibold text-gray-600 mt-2">
            Trading Blocked By User
          </p>
          <Switch
            className="my-2"
            checked={brokerAccountDetails.trade_suspended_by_user}
          />
        </div>
      </div>
    </article>
  );
};
