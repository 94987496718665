import { useEffect, useState } from "react";
import { BrokerTable } from "../components/BrokerTable";
import { useBrokersService } from "../providers";
import { PlusCircle } from "@phosphor-icons/react";
import { Modal } from "@mantine/core";
import { AddBrokerModal } from "../components/AddBrokerModal";
import { BrokerTableSkeletonLoader } from "../components/BrokerTableSkeletonLoader";
import { useGetBrokers } from "../hooks/useGetBrokers";

export const BrokerDashboard = () => {
  const {
    data: brokers,
    isLoading,
    error,
  } = useGetBrokers({ skip: 0, limit: 100 });
  const [isModalOpened, setIsModalOpened] = useState(false);
  
  if(error){
    return <div> {error.message} </div>
  }
  return (
    <div>
      <div className="bg-white rounded px-5 py-4">
        {isLoading ? (
          <div>
            <BrokerTableSkeletonLoader />
          </div>
        ) : (
          <div>
            <div className="flex gap-5 items-center my-3">
              <h2 className="text-lg font-bold"> Brokers</h2>
              <button
                onClick={() => setIsModalOpened(true)}
                className="flex items-center border-2 gap-2 border-gray-900 pr-7 pl-2 py-2 rounded hover:bg-gray-100 transition ease-in-out delay-150"
              >
                <PlusCircle size={20} weight="fill" />
                <p className="text-sm">Add Broker</p>
              </button>
            </div>
            <BrokerTable brokers={brokers ?? []} />
            <Modal
              opened={isModalOpened}
              onClose={() => setIsModalOpened(false)}
              centered={true}
              size="auto"
              withCloseButton={false}
            >
              <AddBrokerModal />
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};
