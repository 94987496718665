import { InputField } from "modules/@core/components/InputField";
import { useFormik } from "formik";
import { useState } from "react";
import notification from "modules/@core/lib/notifications";
import Logo from "assets/icons/logo.png";
import { Button } from "modules/@core/components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import AcceptInviteSchema from "../models/acceptInvite";
import { AcceptInviteRequest } from "../types";
import { useUserService } from "modules/user/providers";

interface FormValues {
  full_name: string;
  password: string;
  confirm_password: string;
}

export const AcceptInviteForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromURL = queryParams.get("token");

  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const initialValues: FormValues = {
    full_name: "",
    password: "",
    confirm_password: "",
  };

  const { userService } = useUserService();

  const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<FormValues>({
      initialValues: initialValues,
      validationSchema: AcceptInviteSchema,
      onSubmit: (values) => {
        acceptInvite(values);
      },
    });

  const acceptInvite = async (formValues: FormValues) => {
    setIsRequestLoading(true);

    if (tokenFromURL) {
      const credentials: AcceptInviteRequest = {
        token: tokenFromURL,
        password: formValues.password,
        full_name: formValues.full_name,
      };
      try {
        await userService.acceptInvite(credentials);
        notification.notify("Invitation successfully accepted. Please log in.");

        navigate("/auth/login");
      } catch (err) {
        notification.notifyError("Unable to recover password");
        console.log(err);
        setIsRequestLoading(false);
      } finally {
        setIsRequestLoading(false);
      }
    }
  };
  return (
    <div className="py-7 px-7 w-full">
      <img src={Logo} alt="Logo" className="h-12 mb-5" />
      <h3 className="font-bold text-lg my-2"> Accept Invite </h3>
      <p className="text-sm">
        Please enter your full name and password to join the platform
      </p>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Full Name"
          name="full_name"
          className="my-3"
          placeholder="Your Full Name"
          value={values.full_name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.full_name && errors.full_name ? errors.full_name : undefined
          }
        />
        <InputField
          label="Password"
          name="password"
          type="password"
          className="my-3"
          placeholder="Your new password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.password && errors.password ? errors.password : undefined
          }
        />
        <InputField
          label="Confirm Password"
          name="confirm_password"
          type="password"
          className="my-3"
          placeholder="Your new password"
          value={values.confirm_password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.confirm_password && errors.confirm_password
              ? errors.confirm_password
              : undefined
          }
        />

        <div className="flex align-center gap-3">
          <Button
            type="submit"
            loading={isRequestLoading}
            className="mt-5"
            disabled={isRequestLoading}
          >
            {isRequestLoading ? "Loading..." : "Accept Invite"}
          </Button>
        </div>
      </form>
    </div>
  );
};
