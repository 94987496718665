import React, { useState } from "react";
import LoginSchema from "modules/auth/models/loginSchema";

import { useFormik } from "formik";
import { useAuth } from "modules/auth/providers";
import { LoginCredentialsDTO, LoginResponse } from "../types";
import { useUserService } from "modules/user/providers";
import { useNavigate } from "react-router-dom";
import notification from "modules/@core/lib/notifications";
import { InputField } from "modules/@core/components/InputField";
import { Button } from "modules/@core/components/Button";
import { PasswordField } from "modules/@core/components/PasswordField";

interface FormValues {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { authService, setToken } = useAuth();
  const { setUser, userService } = useUserService();
  const navigate = useNavigate();

  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<LoginCredentialsDTO>({
      initialValues: initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values) => {
        loginUser(values);
      },
    });

  /**
   * Handle the user login process.
   * Makes an API call to login the user and handles the result.
   * @param credentials - The user's login credentials.
   */
  const loginUser = async (credentials: LoginCredentialsDTO) => {
    setIsRequestLoading(true);
    try {
      const resp = await authService.login(credentials);
      handleLoginSuccess(resp.data);
    } catch (err) {
      // Check if the user is offline.
      if (!navigator.onLine) {
        notification.notifyError(
          "Internet connection appears to be offline. Please check your connection."
        );
      } else {
        // For other types of errors, notify the user that their login credentials are invalid.
        notification.notifyError("Your login credentials are invalid.");
      }
      console.error(err);
      setIsRequestLoading(false);
    } finally {
      setIsRequestLoading(false);
    }
  };

  const handleLoginSuccess = async (data: LoginResponse) => {
    const authToken = data.access_token;
    setToken(authToken);

    // Fetch user and store in state
    try {
      const user = await userService.getCurrentUserData();
      setUser(user);
    } catch (err) {}
    // Navigate to Dashboard
    navigate("/dashboard/overview");
  };

  return (
    <form onSubmit={handleSubmit} className="mt-32">
      <div className="my-5">
        <h1 className="text-3xl font-bold">Welcome Back</h1>
        <p className="text-gray-500">Please enter your details</p>
      </div>

      <InputField
        label="Email"
        name="email"
        className="my-3"
        placeholder="Email Address"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && errors.email ? errors.email : undefined}
      />

      <PasswordField
        label="Password"
        type="password"
        name="password"
        placeholder="Password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touched.password && errors.password ? errors.password : undefined
        }
      />

      <Button fullWidth={true} loading={isRequestLoading} className="my-4">
        {isRequestLoading ? "Logging in..." : "Login"}
      </Button>
      <p
        className="text-right mt-3 font-semibold text-sm cursor-pointer hover:text-primary-100"
        onClick={() => navigate("/auth/recover-password")}
      >
        Forgot your Password?
      </p>
    </form>
  );
};
