import { Navigate } from "react-router-dom";
import { useAuth } from "modules/auth/providers";

type GuardedRouteProps = {
  component: JSX.Element;
};

/** Route to restrict access from authentication */
const GuardedRoute = ({ component }: GuardedRouteProps) => {
  const { isAuthenticated } = useAuth();
  console.log(isAuthenticated);
  return <>{isAuthenticated ? component : <Navigate to="/auth/login" />}</>;
};

export default GuardedRoute;
