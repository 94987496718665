import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { Broker, GetBrokersRequest } from "modules/broker/types";

const getBroker = async (brokerId: number | null) => {
  return (await axios.get<Broker>(`/broker/${brokerId}`)).data;
};

export const useGetBroker = (brokerId: number | null) => {
  return useQuery<Broker, Error>({
    queryKey: ["broker", brokerId],
    queryFn: () => getBroker(brokerId),
    enabled: brokerId !== null,
  });
};
