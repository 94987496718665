import { PlusCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { StrategyTable } from "../components/StrategyTable";
import { useGetCurrentUser } from "modules/user/hooks/useGetUserData";
import { useGetUserStrategies } from "../hooks/useGetUserStrategies";
import { Modal } from "@mantine/core";
import CreateStrategyModal from "../components/CreateStrategyModal";

export const StrategiesDashboard = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { data: user } = useGetCurrentUser();

  const { data: strategies} = useGetUserStrategies(user?.id);

  return (
    <div>
      <div className="flex gap-5 items-center my-3">
        <h2 className="text-lg font-bold"> My Strategies</h2>
        <button
          onClick={() => setIsModalOpened(true)}
          className="flex items-center border-2 gap-2 border-gray-900 pr-7 pl-2 py-2 rounded hover:bg-gray-100 transition ease-in-out delay-150"
        >
          <PlusCircle size={20} weight="fill" />
          <p className="text-sm">Add Strategy</p>
        </button>
      </div>

      <StrategyTable userStrategies={strategies ?? []} />
      <Modal
        opened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        centered={true}
        size="lg"
        withCloseButton={false}
      >
        <CreateStrategyModal userId={user?.id!} />
      </Modal>
    </div>
  );
};
