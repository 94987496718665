import React from "react";
import type { BrokerPosition } from "../types";
import { DataTable } from "modules/@core/components/DataTable"; // Import the DataTable component and types
import type { TableColumn } from "modules/@core/components/DataTable";
import { useNavigate } from "react-router-dom";

interface PositionsTableProps {
  positions: BrokerPosition[];
  expand?: (position: BrokerPosition) => React.ReactNode;
}

export const BrokerPositionsTable: React.FC<PositionsTableProps> = ({
  positions,
  expand,
}) => {
  const navigate = useNavigate();

  // Column definitions
  const columns: TableColumn<BrokerPosition>[] = [
    {
      title: "Position",
      render: (strategy: BrokerPosition) => (
        <div className="flex items-center gap-5">{strategy.symbol}</div>
      ),
    },
    {
      title: "Current Price",
      render: (position: BrokerPosition) => <p> ${position.current_price} </p>,
    },
    {
      title: "Market Value",
      render: (position: BrokerPosition) => (
        <p>${parseFloat(position.market_value).toFixed(2)} </p>
      ),
    },

    {
      title: "Side",
      render: (position: BrokerPosition) => <p> {position.side}</p>,
    },
    { title: "Quantity", render: (position: BrokerPosition) => position.qty },
    {
      title: "Exchange",
      render: (position: BrokerPosition) => <p>{position.exchange}</p>,
    },
    {
      title: "Cost Basis",
      render: (position: BrokerPosition) => (
        <p>${parseFloat(position.cost_basis).toFixed(2)}</p>
      ),
    },
    {
      title: "Unrealized P/L",
      render: (position: BrokerPosition) => (
        <p
          className={`${
            parseFloat(position.unrealized_pl) > 0
              ? "text-green-600"
              : "text-red-600"
          }`}
        >
          ${parseFloat(position.unrealized_pl).toFixed(2)}
        </p>
      ),
    },
  ];

  if (expand) {
    columns.forEach(column => {
      column.expand = expand;
    });
  }

  return <DataTable data={positions} columns={columns} itemsPerPage={10} />;
};
