import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { GetStrategiesRequest, Strategy } from "../types";
import { strategyQueryKeys } from ".";

const getStrategies = async (params: GetStrategiesRequest) => {
  return (
    await axios.get(`/strategies/?skip=${params.skip}&limit=${params.limit}`)
  ).data;
};

export const useGetStrategies = (params: GetStrategiesRequest) => {
  return useQuery<Strategy[], Error>({
    queryKey: [...strategyQueryKeys.strategies, params],
    queryFn: () => getStrategies(params),
  });
};
