import { UserStrategyStatus, type UserStrategy } from "../types";
import {
  DataTable,
  TableColumn,
  TableAction,
} from "modules/@core/components/DataTable"; // Import the DataTable component and types
import { useGetBrokers } from "modules/broker/hooks/useGetBrokers";
import { useGetStrategies } from "../hooks/useGetStrategies";
import { useDeleteUserStrategy } from "../hooks/useDeleteUserStrategy";
import { Loader, Modal, Tooltip } from "@mantine/core";
import { useState } from "react";
import ConfirmationDialog from "modules/@core/components/ConfirmationDialog";
import { useGetUserStrategyStatus } from "../hooks/useGetStrategyStatus";
import { Power } from "@phosphor-icons/react";
import { useStartStrategy } from "../hooks/useStartUserStrategy";
interface StrategyTableProps {
  userStrategies: UserStrategy[];
}

interface StrategyRowProps {
  strategyId: number;
}
const StrategyRow = ({ strategyId }: StrategyRowProps) => {
  const {
    data: status,
    isLoading,
    isError,
    error,
  } = useGetUserStrategyStatus(strategyId);

  const { mutate: startStrategy } = useStartStrategy();
  if (isLoading) {
    return <Loader size="xs" />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const strategyRunning = status?.status === UserStrategyStatus.RUNNING;

  return (
    <Tooltip
      className="hover:bg-gray-200 hover:cursor-pointer   text-center"
      label={strategyRunning ? "Stop Strategy" : "Start Strategy"}
    >
      <Power
        onClick={() => {
          strategyRunning
            ? console.log("Strategy Running")
            : startStrategy(strategyId);
        }}
        size={16}
        className={`${
          strategyRunning ? "text-primary-100" : "text-gray-600"
        } hover:cursor-pointer`}
      />
    </Tooltip>
  );
};

export const StrategyTable = ({ userStrategies }: StrategyTableProps) => {
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] =
    useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState<UserStrategy | null>(
    null
  );
  const { mutate: deleteStrategy, isPending } = useDeleteUserStrategy();

  const { data: brokers } = useGetBrokers({
    skip: 0,
    limit: 100,
  });

  const { data: strategies } = useGetStrategies({ skip: 0, limit: 100 });

  const columns: TableColumn<UserStrategy>[] = [
    {
      title: "Strategy",
      render: (strategy: UserStrategy) => (
        <>
          {strategies?.length
            ? strategies?.find((element) => element.id === strategy.strategy_id)
                ?.name
            : ""}
        </>
      ),
    },
    {
      title: "Broker",
      render: (strategy: UserStrategy) => (
        <>
          {brokers?.length
            ? brokers?.find((element) => element.id === strategy.broker_id)
                ?.broker_name
            : ""}
        </>
      ),
    },
    {
      title: "Symbol",
      render: (strategy: UserStrategy) => <> {strategy.symbol}</>,
    },
    {
      title: "Timeframe",
      render: (strategy: UserStrategy) => <> {strategy.timeframe}</>,
    },
    {
      title: "Risk Per Trade",
      render: (strategy: UserStrategy) => <> {strategy.risk_per_trade}%</>,
    },
    {
      title: "",
      render: (strategy: UserStrategy) => {
        return <StrategyRow strategyId={strategy.id} />;
      },
    },
  ];

  const onStrategyDelete = (strategy: UserStrategy) => {
    // Set the strategy to be deleted
    setSelectedStrategy(strategy);

    // Open the confirmation dialog
    setIsConfirmationDialogOpened(true);
  };

  const handleConfirmDelete = () => {
    if (selectedStrategy) {
      deleteStrategy(selectedStrategy.id);

      // Close the dialog
      setIsConfirmationDialogOpened(false);

      // Reset the selected strategy
      setSelectedStrategy(null);
    }
  };

  const handleCancelDelete = () => {
    // Close the dialog
    setIsConfirmationDialogOpened(false);

    // Reset the selected strategy
    setSelectedStrategy(null);
  };

  const strategyActions: TableAction<UserStrategy>[] = [
    {
      label: "Delete Strategy",
      handler: onStrategyDelete,
    },
  ];

  return (
    <>
      <DataTable
        data={userStrategies}
        columns={columns}
        actions={strategyActions}
        itemsPerPage={4}
      />

      <Modal
        opened={isConfirmationDialogOpened}
        onClose={() => setIsConfirmationDialogOpened(false)}
        centered={true}
        size="auto"
        withCloseButton={false}
      >
        <ConfirmationDialog
          title="Delete Strategy"
          description="Are you sure you want to delete this strategy? This action cannot be undone."
          confirmText="Delete"
          isPending={isPending}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      </Modal>
    </>
  );
};
