import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { StrategyStatus } from "../types";
import { strategyQueryKeys } from ".";

const getUserStrategyStatus = async (strategyId?: number) => {
  return (await axios.get(`/user-strategy/${strategyId}/status`)).data;
};

export const useGetUserStrategyStatus = (strategyId?: number) => {
  return useQuery<StrategyStatus, Error>({
    queryKey: [...strategyQueryKeys.userStrategies, strategyId],
    queryFn: () => getUserStrategyStatus(strategyId),
    enabled: strategyId !== undefined,
  });
};
