import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { DailyChangeResponse } from "modules/broker/types";

const getDailyChange = async (brokerId: number | null) => {
  return (
    await axios.get<DailyChangeResponse>(
      `/broker/${brokerId}/daily-portfolio-change`
    )
  ).data;
};

export const useGetDailyChange = (brokerId: number | null) => {
  return useQuery<DailyChangeResponse, Error>({
    queryKey: ["broker-daily-change", brokerId],
    queryFn: () => getDailyChange(brokerId),
    enabled: brokerId !== null,
  });
};
