import { createBrowserRouter } from "react-router-dom";
import { Navigate } from "react-router";
import { authRoutes } from "modules/auth/routes";
import { dashboardRoutes } from "modules/dashboard/routes";

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/dashboard/overview" />,
  },

  ...authRoutes,
  ...dashboardRoutes,
]);
