import {
  Graph,
  Handshake,
  SquaresFour,
  Target,
  Users,
} from "@phosphor-icons/react";
import NavItem, { NavItemProps } from "./NavItem";

type AsideProps = {
  isDrawerOpen: boolean;
};

const items: NavItemProps[] = [
  {
    name: "Dashboard",
    link: "/dashboard/overview",
    icon: <SquaresFour size="24" weight="duotone" />,
  },
  {
    name: "Strategies",
    link: "/dashboard/strategies",
    icon: <Target size="24" weight="duotone" />,
  },
  {
    name: "Brokers",
    link: "/dashboard/brokers",
    icon: <Handshake size="24" weight="duotone" />,
  },
  {
    name: "Users",
    link: "/dashboard/users",
    icon: <Users size="24" weight="duotone" />,
  },
  {
    name: "Trades",
    link: "/dashboard/trades",
    icon: <Graph size="24" weight="duotone" />,
  },
];

export const Aside = ({ isDrawerOpen }: AsideProps) => {
  return (
    <aside
      className={`fixed top-0 z-50 h-screen w-[234px] shrink-0 border-r border-gray-200 bg-white py-12 transition-all md:sticky ${
        isDrawerOpen ? "translate-x-0" : "-translate-x-full md:translate-x-0"
      }`}
    >
      {/* Options */}
      <nav>
        <ul className="flex flex-col space-y-2">
          {items.map((item) => (
            <NavItem key={item.name} {...item} />
          ))}
        </ul>
      </nav>
    </aside>
  );
};
