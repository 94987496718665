import { Header } from "modules/dashboard/components/Header";
import { Aside } from "modules/dashboard/components/Aside";
import { Outlet } from "react-router-dom";

export const Dashboard = () => {
  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-grow">
        <Aside isDrawerOpen={false} />
        <main className="flex-grow m-4 ">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
