import {
  DataTable,
  TableColumn,
  TableAction,
} from "modules/@core/components/DataTable";
import { User } from "../types"; // Assuming this is the correct path
import { Badge } from "modules/@core/components/Badge";
import { useUserService } from "../providers";
import notification from "modules/@core/lib/notifications";

interface UsersTableProps {
  users: User[];
}

export const UsersTable = ({ users }: UsersTableProps) => {
  // Define the actions for each user

  const { userService, setUsers } = useUserService();
  const userActions: TableAction<User>[] = [
    {
      label: "Edit User",
      handler: (user: User) => {
        // Implement the edit functionality
        console.log("Edit", user);
      },
    },
    {
      label: "Delete User",
      handler: async (user: User) => {
        try {
          await userService.deleteUser(user.id);
          notification.notify("User Successfully deleted");
        } catch (err) {
          notification.notifyError("Unable to delete user");
        }

        try {
          const users = await userService.getAllUsers();
          setUsers(users);
        } catch (err) {
          notification.notifyError("Error fetching users");
        }
      },
    },
  ];

  // Define the columns for the users table
  const userColumns: TableColumn<User>[] = [
    {
      title: "Name",
      render: (user: User) => <span>{user.full_name}</span>,
    },
    {
      title: "Email Address",
      render: (user: User) => <span>{user.email}</span>,
    },
    {
      title: "Role",
      render: (user: User) => <span>{user.role_name}</span>,
    },
    {
      title: "Status",
      render: (user: User) =>
        user.is_active ? (
          <Badge color="green">Active</Badge>
        ) : (
          <Badge color="red">Disabled</Badge>
        ),
    },
  ];

  return (
    <div>
      <DataTable
        tableHeader="Users"
        data={users}
        columns={userColumns}
        actions={userActions}
        itemsPerPage={4}
      />
    </div>
  );
};
