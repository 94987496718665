import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import { strategyQueryKeys } from ".";
import { Strategy } from "../types";
import notification from "modules/@core/lib/notifications";

export const useStartStrategy = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, number, { previousStrategies?: Strategy[] }>({
    mutationFn: async (strategyId) => {
      await axios.post(`/user-strategy/start/${strategyId}`);
    },

    // When mutation is successful, refetch the 'user-strategies' query
    onSettled: () => {
      notification.notify("Successfully Started Strategy");
      queryClient.invalidateQueries({
        queryKey: strategyQueryKeys.userStrategies,
      });
    },

    // If mutation fails, rollback the optimistic update
    onError: (err, strategyDetails, context) => {
      queryClient.setQueryData(
        strategyQueryKeys.userStrategies,
        context?.previousStrategies
      );
    },
  });
};
