import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { BrokerAccountDetails } from "modules/broker/types";

const getBrokerDetails = async (brokerId: number | null) => {
  return (
    await axios.get<BrokerAccountDetails>(`/broker/${brokerId}/account-details`)
  ).data;
};

export const useGetBrokerDetails = (brokerId: number | null) => {
  return useQuery<BrokerAccountDetails, Error>({
    queryKey: ["broker-account-details", brokerId],
    queryFn: () => getBrokerDetails(brokerId),
    enabled: brokerId !== null,
  });
};
