import { useQuery } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { Broker, GetBrokersRequest } from "modules/broker/types";

const getBrokers = async (params: GetBrokersRequest) => {
  return (await axios.get(`/broker/?skip=${params.skip}&limit=${params.limit}`))
    .data;
};

export const useGetBrokers = (params: GetBrokersRequest) => {
  return useQuery<Broker[], Error>({
    queryKey: ["brokers", params],
    queryFn: () => getBrokers(params),
  });
};
