import * as Yup from 'yup';

const AcceptInviteRequest = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long'),
    confirm_password: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export default AcceptInviteRequest;
