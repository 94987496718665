import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { Strategy } from "../types";
import notification from "modules/@core/lib/notifications";
import { strategyQueryKeys } from ".";

const deleteUserStrategy = async (userStrategyId: number) => {
  return (await axios.delete<void>(`/user-strategy/${userStrategyId}/`)).data;
};

export const useDeleteUserStrategy = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, number, { previousStrategies?: Strategy[] }>({
    mutationFn: async (userStrategyId) => {
      await deleteUserStrategy(userStrategyId);

      // Optimistic update: Remove the strategy from 'user-strategies' query data
      queryClient.setQueryData<Strategy[]>(strategyQueryKeys.userStrategies, (old) =>
        old ? old.filter((strategy) => strategy.id !== userStrategyId) : []
      );
    },

    // When mutation is successful, refetch the 'user-strategies' query
    onSettled: () => {
      notification.notify("User strategy successfully deleted");
      queryClient.invalidateQueries({ queryKey: strategyQueryKeys.userStrategies });
    },

    // If mutation fails, rollback the optimistic update
    onError: (err, userStrategyId, context) => {
      queryClient.setQueryData(
        strategyQueryKeys.userStrategies,
        context?.previousStrategies
      );
    },
  });
};
