interface EmptyTableContentProps {
  heading: string;
  body: string;
  imageUrl: string;
}
export const EmptyTableContent = ({
  heading,
  body,
  imageUrl,
}: EmptyTableContentProps) => {
  return (
    <>
      <img src={imageUrl} alt={`Image representing ${body}`} />
      <h2> {heading} </h2>
      <p> {body} </p>
    </>
  );
};
