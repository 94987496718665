import React from "react";
import { Loader } from "@mantine/core";

type ButtonProps = {
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  variant?: "primary" | "secondary" | 'tertiary';
  fullWidth?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  iconPosition = "left",
  variant = "primary",
  fullWidth = false,
  loading = false,
  className = "",
  onClick,
  ...props
}) => {
  const baseStyles =
    "py-2 px-4 font-semibold rounded transition ease-in-out delay-150 flex justify-center items-center";
  const primaryStyles =
    "bg-primary-500 text-white text-sm hover:bg-primary-900";
  const secondaryStyles =
    "bg-white border-2 border-gray-500 text-sm hover:bg-gray-100";

  const tertiaryStyles =
    "bg-white border border-gray-200 text-sm hover:bg-gray-100";

  const loadingStyles = "flex justify-center items-center";

  const variantStyles =
    variant === "primary"
      ? primaryStyles
      : variant === "secondary"
      ? secondaryStyles
      : tertiaryStyles;
  const combinedStyles = `${baseStyles} ${variantStyles} ${
    loading ? loadingStyles : ""
  } ${className} ${fullWidth ? "w-full" : ""}`;

  return (
    <button
      disabled={loading}
      className={combinedStyles}
      onClick={onClick}
      {...props}
    >
      <>
        {loading && <Loader size="sm" className="mx-3" color="white" />}
        {icon && iconPosition === "left" && (
          <span className="mr-2">{icon}</span>
        )}
        {children}
        {icon && iconPosition === "right" && (
          <span className="ml-2">{icon}</span>
        )}
      </>
    </button>
  );
};
