import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { theme } from "config/mantineTheme";
import { axios } from "modules/@core/lib/axios";
import { AuthProvider } from "modules/auth/providers";
import { BrokersContextProvider } from "modules/broker/providers";
import BrokerService from "modules/broker/services";
import { StrategiesContextProvider } from "modules/strategy/providers";
import StrategyService from "modules/strategy/services";
import { UserContextProvider } from "modules/user/providers";
import UserService from "modules/user/services";
import { ToastContainer } from "react-toastify";

interface Props {
  children: React.ReactNode;
}

export const AppProviders = ({ children }: Props) => {
  const queryClient = new QueryClient();

  return (
    <MantineProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UserContextProvider userService={new UserService(axios)}>
            <StrategiesContextProvider
              strategyService={new StrategyService(axios)}
            >
              <BrokersContextProvider brokerService={new BrokerService(axios)}>
                {children}
                <ToastContainer newestOnTop />
              </BrokersContextProvider>
            </StrategiesContextProvider>
          </UserContextProvider>
        </AuthProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
};
