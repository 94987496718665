import { useQuery } from "@tanstack/react-query";
import { BrokerPosition } from "../types";
import { axios } from "modules/@core/lib/axios";

const FETCH_INTERVAL_MS = 60000;

/**
 * Fetches open positions for a specified broker.
 *
 * @param {number} brokerId - The broker's unique identifier.
 * @returns {Promise<BrokerPosition[]>} A promise resolving to the broker positions.
 */
const fetchOpenPositions = async (
  brokerId: number
): Promise<BrokerPosition[]> => {
  try {
    return (
      await axios.get<BrokerPosition[]>(`/broker/${brokerId}/open-positions`)
    ).data;
  } catch (error) {
    return [];
  }
};

/**
 * Custom hook to fetch and poll a broker's open positions.
 * Automatically polls at the specified interval.
 *
 * @param {number | undefined} brokerId - The broker's unique identifier.
 * @returns The useQuery hook result containing open positions, loading state, and error.
 */
export const useOpenPositions = (brokerId?: number) => {
  return useQuery<BrokerPosition[], Error>({
    queryKey: ["openPositions", brokerId],
    queryFn: () =>
      brokerId !== undefined
        ? fetchOpenPositions(brokerId!)
        : Promise.resolve([]),

    enabled: brokerId !== undefined,
    refetchInterval: FETCH_INTERVAL_MS,
  });
};
