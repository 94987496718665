import type { AxiosInstance } from "axios";
import { Broker, BrokerAccountDetails, BrokerCreate, BrokerPosition, DailyChangeResponse, GetBrokersRequest, SupportedBroker } from "modules/broker/types";

/**
 * `BrokerService` provides methods for performing broker-related operations.
 * It uses the provided Axios instance to make HTTP requests.
 */
export default class BrokerService {
    axios: AxiosInstance;

    /**
     * Constructs a new instance of `BrokerService`.
     * 
     * @param axios - An Axios instance used for making HTTP requests.
     */
    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }


    async getBrokers({ skip, limit }: GetBrokersRequest) {
        return (await this.axios.get<Broker[]>(`/broker/?skip=${skip}&limit=${limit}`)).data;
    };

    async disconnectBroker(brokerId: number) {
        return (await this.axios.post(`broker/${brokerId}/disconnect`)).data;
    }

    async connectBroker(brokerId: number) {
        return (await this.axios.post(`broker/${brokerId}/connect`)).data;
    }

    async getDailyChange(brokerId: number) {
        return (await this.axios.get<DailyChangeResponse>(`/broker/${brokerId}/daily-portfolio-change`)).data
    }

    async createBroker(userId: number, credentials: BrokerCreate) {

        switch (credentials.broker_name) {
            case SupportedBroker.ALPACA:
                // delete credentials.username
                // delete credentials.password
                break;
            case SupportedBroker.IGTRADING:
                delete credentials.secret_key
                break;
        }

        return (await this.axios.post<Broker>(`/broker/create/?user_id=${userId}`, {
            ...credentials
        })).data
    }

    async getBroker(brokerId: number) {
        return (await this.axios.get<Broker>(`/broker/${brokerId}`)).data;
    }

    async getOpenPositions(brokerId: number) {
        return (await this.axios.get<BrokerPosition[]>(`/broker/${brokerId}/open-positions`)).data;
    }
    async getBrokerDetails(brokerId: number) {
        return (await this.axios.get<BrokerAccountDetails>(`/broker/${brokerId}/account-details`)).data;
    }
}
