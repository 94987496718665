import { RouteObject } from "react-router-dom";
import { UserDashboard } from "../pages/UserDashboard";

export const userRoutes: RouteObject[] = [
  {
    path: "users/*",
    children: [
      {
        path: "",
        element: <UserDashboard />,
      },
    ],
  },
];
