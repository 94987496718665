import React from "react";
import ContentLoader from "react-content-loader";

const PositionsOverviewLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={210} // Increased height to fit 5 rows
    viewBox="0 0 400 210"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    {/* Header */}
    <rect x="0" y="0" rx="4" ry="4" width="390" height="20" />

    {/* Rows */}
    {Array.from({ length: 5 }).map((_, index) => (
      <React.Fragment key={index}>
        <rect x="0" y={45 + 35 * index} rx="4" ry="4" width="90" height="18" />
        <rect
          x="100"
          y={45 + 35 * index}
          rx="4"
          ry="4"
          width="90"
          height="18"
        />
        <rect
          x="200"
          y={45 + 35 * index}
          rx="4"
          ry="4"
          width="90"
          height="18"
        />
        <rect
          x="300"
          y={45 + 35 * index}
          rx="4"
          ry="4"
          width="90"
          height="18"
        />
      </React.Fragment>
    ))}
  </ContentLoader>
);

export default PositionsOverviewLoader;
