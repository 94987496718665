import React, { useMemo, useState } from "react";
import PositionsOverviewLoader from "./PositionsOverviewLoader";
import { useOpenPositions } from "../hooks/useOpenPositions";
import { AssetType, PositionSideType } from "modules/@core/types/assets";
import { FilterSelect } from "modules/@core/components/FilterSelect";
import { useNavigate } from "react-router-dom";

interface PositionsOverviewProps {
  brokerId: number;
}

export const PositionsOverview = ({ brokerId }: PositionsOverviewProps) => {
  const { data: openPositions, isLoading, error } = useOpenPositions(brokerId);
  const [assetClass, setAssetClass] = useState<"all" | AssetType>("all");
  const [positionSide, setPositionSide] = useState<"all" | PositionSideType>(
    "all"
  );

  const filteredPositions = useMemo(() => {
    if (openPositions) {
      return openPositions.filter((position) => {
        const assetClassMatch =
          assetClass === "all" || position.asset_class === assetClass;
        const positionSideMatch =
          positionSide === "all" || position.side === positionSide;
        return assetClassMatch && positionSideMatch;
      });
    } else {
      return [];
    }
  }, [openPositions, assetClass, positionSide]);

  const positionsMarkup = useMemo(
    () =>
      filteredPositions.map((position, index) => (
        <tr
          key={position.symbol}
          className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}
        >
          <th
            scope="row"
            className="px-2 py-2 font-semibold text-gray-900 whitespace-nowrap"
          >
            {position.symbol}
          </th>
          <td className="px-3 text-gray-600 py-2">
            ${parseFloat(position.current_price).toFixed(2)}
          </td>
          <td className="px-2 text-gray-600 py-2">
            {parseFloat(position.qty).toFixed(2)}
          </td>
          <td className="px-2 text-gray-600 py-2">
            ${parseFloat(position.market_value).toFixed(2)}
          </td>
          <td
            className={`px-2 ${
              parseFloat(position.unrealized_pl) > 0
                ? "text-green-500"
                : "text-red-500"
            } py-2`}
          >
            {" "}
            ${parseFloat(position.unrealized_pl).toFixed(2)}
          </td>
        </tr>
      )),
    [filteredPositions]
  );

  const navigate = useNavigate();

  return (
    <article className="mx-5 bg-white p-6 rounded-lg border border-gray-200">
      {isLoading ? (
        <PositionsOverviewLoader />
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <>
          <div className="flex justify-between">
            <h3 className="text-gray-700 font-semibold text-lg">
              Positions Overview
            </h3>
            <p
              className="font-bold text-primary-300 text-xs hover:cursor-pointer hover:text-blue-900"
              onClick={() =>
                navigate(`/dashboard/brokers/positions/${brokerId}`)
              }
            >
              View All
            </p>
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="flex items-center text-xs my-2">
              <p className="py-2.5 border-gray-100 bg-gray-100 px-4 rounded-l-md font-bold text-gray-500">
                Asset Class
              </p>

              <FilterSelect
                placeholder="Select asset class"
                className="w-1/2"
                value={assetClass}
                onChange={(value) => setAssetClass(value as AssetType)}
                data={[
                  { value: "all", label: "All" },
                  { value: AssetType.EQUITY, label: "Equity" },
                  { value: AssetType.CRYPTO, label: "Crypto" },
                ]}
              />
            </div>
            <div className="flex items-center text-xs my-2">
              <p className="py-2.5 border-gray-100 bg-gray-100 px-2 rounded-l-md font-bold text-gray-500">
                Side
              </p>

              <FilterSelect
                placeholder="Select position side"
                className="w-1/2"
                value={assetClass}
                onChange={(value) => setPositionSide(value as PositionSideType)}
                data={[
                  { value: "all", label: "All" },
                  { value: PositionSideType.LONG, label: "Long" },
                  { value: PositionSideType.SHORT, label: "Short" },
                ]}
              />
            </div>
          </div>

          <table className="w-full text-sm text-left px-2">
            <thead className="text-sm quants-gray-300 text-gray-700 bg-white">
              <tr>
                <td className="px-2 text-gray-600 py-3 text-sm font-bold">
                  Asset
                </td>
                <td className="px-2 text-gray-600 py-3 text-sm font-bold">
                  Price
                </td>
                <td className="px-2 text-gray-600 py-3 text-sm font-bold">
                  Qty
                </td>
                <td className="px-2 text-gray-600 py-3 text-sm font-bold">
                  Market Value
                </td>
                <td className="px-2 text-gray-600 py-3 text-sm font-bold">
                  Unrealized P/L
                </td>
              </tr>
            </thead>
            {positionsMarkup.length ? (
              <tbody>{positionsMarkup}</tbody>
            ) : (
              <p className="text-center"></p>
            )}
          </table>
        </>
      )}
    </article>
  );
};
