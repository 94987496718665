import type { AxiosInstance } from "axios";
import {
    LoginCredentialsDTO,
    LoginResponse,
    PasswordRecoveryResponse,
    PasswordResetDTO,
    PasswordResetResponse
} from 'modules/auth/types';

/**
 * `AuthenticationService` provides methods for performing authentication-related operations.
 * It uses the provided Axios instance to make HTTP requests.
 */
export default class AuthService {
    axios: AxiosInstance;

    /**
     * Constructs a new instance of `AuthenticationService`.
     * 
     * @param axios - An Axios instance used for making HTTP requests.
     */
    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * Attempts to login a user with the provided credentials.
     * 
     * @param credentials - An object containing the user's login details.
     * @returns A promise that resolves to the login response.
     */
    login(credentials: LoginCredentialsDTO) {
        return this.axios.post<LoginResponse>('/auth/token', {
            ...credentials,
        });
    };

    /**
     * Resets the password for a user using a provided token.
     * 
     * @param {string} token - A token used for validating the password reset.
     * @param {string} new_password - The new password to set.
     * @returns A promise that resolves to the password reset response.
     */
    resetPassword({ token, new_password }: PasswordResetDTO) {
        return this.axios.post<PasswordResetResponse>('/auth/reset-password', {
            token: token,
            new_password: new_password
        })
    }

    /**
     * Initiates the password recovery process for a user with the given email.
     * 
     * @param email - The email address of the user requesting password recovery.
     * @returns A promise that resolves to the password recovery response.
     */
    recoverPassword(email: string) {
        return this.axios.post<PasswordRecoveryResponse>(`/auth/password-recovery/${email}`)
    }
}
