import { AxiosInstance } from "axios";
import { InviteUserRequest, UserInvitation, User } from "../types";
import { AcceptInviteRequest } from "modules/auth/types";

/**
 * `UserService` provides methods for performing user-related operations.
 * It uses the provided Axios instance to make HTTP requests.
 */
export default class UserService {
    private axios: AxiosInstance;

    /**
     * Constructs a new instance of `UserService`.
     * 
     * @param {AxiosInstance} axios - An Axios instance used for making HTTP requests.
     */
    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * Fetches the current user's data.
     * 
     * @returns {Promise<User>} A promise that resolves to the current user's data.
     */
    async getCurrentUserData(): Promise<User> {
        return (await this.axios.get<User>('/user/user/me')).data;
    }

    /**
     * Fetches all users.
     * 
     * @returns {Promise<User[]>} A promise that resolves to an array of all users.
     */
    async getAllUsers(): Promise<User[]> {
        return (await this.axios.get<User[]>('/user/users')).data;
    }
    
    /**
     * Fetches all user invitations.
     * 
     * @returns {Promise<UserInvitation>} A promise that resolves to an object containing all user invitations.
     */
    async getAllInvitations(): Promise<UserInvitation[]> {
        return (await this.axios.get<UserInvitation[]>('/user/invitations')).data;
    }

    /**
     * Invites a new user.
     * 
     * @param {InviteUserRequest} inviteUserRequest - An object containing the email and role name of the user to be invited.
     * @returns {Promise<UserInvitation>} A promise that resolves to an object containing the invited user's data.
     */
    async inviteUser({ email, role_name }: InviteUserRequest): Promise<UserInvitation> {
        return (await this.axios.post<UserInvitation>(`/user/invite/?email=${email}&role_name=${role_name}`, {})).data;
    }


    /**
      * Accept Invite
      * 
      * @param {AcceptInviteRequest} credentials - An object containing the email and role name of the user to be invited.
      * @returns {Promise<UserInvitation>} A promise that resolves to an object containing the invited user's data.
      */
    async acceptInvite(credentials: AcceptInviteRequest): Promise<UserInvitation> {
        return (await this.axios.post<UserInvitation>(`/user/accept-invitation`, { ...credentials })).data;
    }

    async deleteUser(userId: number) {
        return (await this.axios.delete<UserInvitation[]>(`/user/users/${userId}`)).data;
    }
}
