import { NavLink } from "react-router-dom";

export type NavItemProps = {
  name: string;
  link: string;
  icon: React.ReactNode;
};

const NavItem = ({ name, link, icon }: NavItemProps) => {
  return (
    <NavLink to={link}>
      {({ isActive }) => (
        <div
          className={`group relative flex items-center text-gray-600 gap-2 fill-gray-dark px-6 py-3 text-sm ${
            isActive
              ? "bg-primary-50 text-gray-700"
              : "text-gray-dark hover:bg-primary-50 hover:text-blue-600"
          }`}
        >
          <span
            className={`absolute left-0 rounded-r-full w-1 h-3/4 bg-primary-100 ${
              isActive ? "block" : "hidden group-hover:block"
            }`}
          />
          <span className={isActive ? "text-primary-100" : ""}>{icon}</span>{" "}
          <span className="font-semibold">{name}</span>
        </div>
      )}
    </NavLink>
  );
};

export default NavItem;
