/**
 * Enum representing the different roles a user can have within the system.
 * @enum {string}
 */
export enum Role {
    ADMIN = 'admin',
    TRADER = 'trader'
}

/**
 * Interface representing a user within the system.
 * @interface
 */
export interface User {
    /** Unique identifier for the user. */
    id: number;
    /** Email address of the user. */
    email: string;
    /** Full name of the user. */
    full_name: string;
    /** Indicates whether the user account is active. */
    is_active: boolean;
    /** Timestamp of when the user account was created. */
    created_at: Date;
    /** Timestamp of the last update to the user account. */
    updated_at: Date;
    /** Role name assigned to the user, indicating their permissions. */
    role_name: Role;
}

/**
 * Interface representing the structure of a request to invite a new user.
 * @interface
 */
export interface InviteUserRequest {
    /** Email address of the user to be invited. */
    email: string;
    /** Role name to be assigned to the invited user. */
    role_name: Role;
}

/**
 * Interface representing the response received when inviting a new user.
 * @interface
 */
export interface UserInvitation {
    /** Email address of the invited user. */
    email: string;
    /** Unique identifier for the invited user. */
    id: number;
    /** Token generated for the invite. */
    token: string;
    /** Expiry date of the invite token. */
    expiry_date: string;
    /** Status of the invite. */
    status: Status;
    /** Timestamp of when the invite was created. */
    created_at: string;
    /** Role name assigned to the invited user. */
    role_name: Role;
}

/**
 * Enum representing the various statuses an invite can have.
 * @enum {string}
 */
export enum Status {
    PENDING = 'Pending',
    ACCEPTED = 'Accepted',
    EXPIRED = 'Expired'
}

