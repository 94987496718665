import React from 'react';
import ContentLoader from 'react-content-loader';

export const BrokerTableSkeletonLoader = ({ ...props }) => {
  const numberOfRows = 5; // The number of rows in your table

  // Define the column widths based on your table design
  const columnWidths = {
    broker: 200,
    type: 130,
    nickname: 180,
    status: 90,
    action: 60 // Assuming a smaller width for the 'action' column
  };

  // Calculate the x positions of the columns
  const columnXPositions = {
    broker: 0,
    type: columnWidths.broker + 20,
    nickname: columnWidths.broker + columnWidths.type + 40,
    status: columnWidths.broker + columnWidths.type + columnWidths.nickname + 60,
    action: columnWidths.broker + columnWidths.type + columnWidths.nickname + columnWidths.status + 80,
    addBrokerButton: 900 // X position for the 'Add Broker' button, adjust as needed
  };

  return (
    <ContentLoader
      speed={2}
      width={1060}
      height={400} // Adjust the height based on your number of rows plus header and button
      viewBox="0 0 1060 400" // Adjust to match width and height
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      {/* "Brokers" Section Header */}
      <rect x="0" y="20" rx="4" ry="4" width="140" height="30" />

      {/* Table Headers */}
      <rect x={columnXPositions.broker} y="70" rx="4" ry="4" width={columnWidths.broker} height="20" />
      <rect x={columnXPositions.type} y="70" rx="4" ry="4" width={columnWidths.type} height="20" />
      <rect x={columnXPositions.nickname} y="70" rx="4" ry="4" width={columnWidths.nickname} height="20" />
      <rect x={columnXPositions.status} y="70" rx="4" ry="4" width={columnWidths.status} height="20" />
      <rect x={columnXPositions.action} y="70" rx="4" ry="4" width={columnWidths.action} height="20" />
      
      {/* Table Rows */}
      {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <rect x={columnXPositions.broker} y={100 + rowIndex * 60} rx="4" ry="4" width={columnWidths.broker} height="20" />
          <rect x={columnXPositions.type} y={100 + rowIndex * 60} rx="4" ry="4" width={columnWidths.type} height="20" />
          <rect x={columnXPositions.nickname} y={100 + rowIndex * 60} rx="4" ry="4" width={columnWidths.nickname} height="20" />
          <rect x={columnXPositions.status} y={100 + rowIndex * 60} rx="4" ry="4" width={columnWidths.status} height="20" />
          <rect x={columnXPositions.action} y={100 + rowIndex * 60} rx="4" ry="4" width={columnWidths.action} height="20" />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
};
