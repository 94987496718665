import { RecoverPasswordForm } from "../components/RecoverPasswordForm";

export const RecoverPasswordPage = () => {
  return (
    <main className="flex h-screen border bg-quants-gray-50">
      <div className="my-auto mx-auto w-1/3 rounded-lg bg-white border-gray-500 shadow-md">
        <RecoverPasswordForm />
      </div>
    </main>
  );
};
