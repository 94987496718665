/**
 * Enum representing supported brokers.
 */
export enum SupportedBroker {
    ALPACA = 'Alpaca',
    IGTRADING = 'IGTrading',
    BINANCE = 'Binance',
    INTERACTIVE_BROKERS = 'Interactive Brokers',
}

/**
 * Enum representing account types.
 */
export enum AccountType {
    LIVE = "Live",
    DEMO = "Demo",
}

/**
 * Represents a broker entity.
 */
export interface Broker {
    /** The unique identifier for the broker. */
    id: number;

    /** The name of the broker, chosen from the SupportedBroker enum. */
    broker_name: SupportedBroker;

    /** The type of the account, chosen from the AccountType enum. */
    account_type: AccountType;

    /** A user-defined nickname for the broker. */
    nick_name: string;

    /** The API key associated with the broker (optional). */
    api_key?: string;

    /** The secret key associated with the broker (optional). */
    secret_key?: string;

    /** The connection status of the broker. */
    connection_status: boolean;
}

/**
 * Represents a response containing daily change information.
 */
export interface DailyChangeResponse {
    /** The daily change value. */
    daily_change: number;
}

/**
 * Represents a request to create a broker, omitting the 'id' field.
 */
export interface BrokerCreate extends Omit<Broker, 'id'> {
    /** The username associated with the broker (optional). */
    username?: string;

    /** The password associated with the broker (optional). */
    password?: string;
}

/**
 * Represents a request to get a list of brokers with pagination.
 */
export interface GetBrokersRequest {
    /** The number of items to skip in the list. */
    skip: number;

    /** The maximum number of items to retrieve. */
    limit: number;
}

/**
 * Represents details of a broker account.
 */
export interface BrokerAccountDetails {
    /** The unique identifier for the broker account. */
    id: string;

    /** The account number associated with the broker account. */
    account_number: string;

    /** The status of the broker account. */
    status: string;

    /** The crypto status of the broker account. */
    crypto_status: string;

    /** The currency of the broker account. */
    currency: string;

    /** The buying power of the broker account. */
    buying_power: string;

    /** The Regulation T (Reg T) buying power of the broker account. */
    regt_buying_power: string;

    /** The day trading buying power of the broker account. */
    daytrading_buying_power: string;

    /** The non-marginable buying power of the broker account. */
    non_marginable_buying_power: string;

    /** The cash balance in the broker account. */
    cash: string;

    /** The accrued fees in the broker account. */
    accrued_fees: string;

    /** The amount pending transfer out of the broker account (nullable). */
    pending_transfer_out: string | null;

    /** The amount pending transfer into the broker account. */
    pending_transfer_in: string;

    /** The total portfolio value in the broker account. */
    portfolio_value: string;

    /** Indicates whether the account holder is classified as a pattern day trader. */
    pattern_day_trader: boolean;

    /** Indicates whether trading is blocked for the broker account. */
    trading_blocked: boolean;

    /** Indicates whether transfers are blocked for the broker account. */
    transfers_blocked: boolean;

    /** Indicates whether the entire account is blocked. */
    account_blocked: boolean;

    /** The date and time when the broker account was created. */
    created_at: string;

    /** Indicates whether the user has suspended trading in the account. */
    trade_suspended_by_user: boolean;

    /** The multiplier associated with the broker account. */
    multiplier: string;

    /** Indicates whether shorting is enabled for the account. */
    shorting_enabled: boolean;

    /** The equity of the broker account. */
    equity: string;

    /** The last recorded equity of the broker account. */
    last_equity: string;

    /** The long market value of the broker account. */
    long_market_value: string;

    /** The short market value of the broker account. */
    short_market_value: string;

    /** The initial margin requirement for the broker account. */
    initial_margin: string;

    /** The maintenance margin requirement for the broker account. */
    maintenance_margin: string;

    /** The last recorded maintenance margin for the broker account. */
    last_maintenance_margin: string;

    /** The Special Memorandum Account (SMA) balance for the broker account. */
    sma: string;

    /** The count of day trades executed in the broker account. */
    daytrade_count: number;
}


export interface BrokerPosition {
    /** The symbol of the asset. */
    symbol: string;

    /** The exchange where the asset is traded. */
    exchange: string;

    /** The asset class (e.g., us_equity). */
    asset_class: string;

    /** The average entry price for the position. */
    avg_entry_price: string;

    /** The quantity of the asset. */
    qty: string;

    /** The quantity of the asset available for trading. */
    qty_available: string;

    /** The side of the position (e.g., "long" or "short"). */
    side: string;

    /** The current market value of the position. */
    market_value: string;

    /** The cost basis of the position. */
    cost_basis: string;

    /** The unrealized profit or loss of the position. */
    unrealized_pl: string;

    /** The current price of the asset. */
    current_price: string;

    /** The value in USD (nullable; use null when unavailable). */
    usd: null | string;
}