import {
  DataTable,
  TableColumn,
  TableAction,
} from "modules/@core/components/DataTable";
import { UserInvitation } from "../types";

interface UserInvitesTableProps {
  invites: UserInvitation[];
}

export const UserInvitesTable = ({ invites }: UserInvitesTableProps) => {
  // Define the actions for each user
  const inviteActions: TableAction<UserInvitation>[] = [
    {
      label: "Delete User",
      handler: (invite) => {
        // Implement the delete functionality
        console.log("Delete", invite);
      },
    },
  ];

  // Define the columns for the users table
  const inviteColumns: TableColumn<UserInvitation>[] = [
    {
      title: "Email Address",
      render: (invite) => <span>{invite.email}</span>,
    },
    {
      title: "Role",
      render: (invite) => <span>{invite.role_name}</span>,
    },
    {
      title: "Status",
      render: (invite) => <span>{invite.status}</span>,
    },
    {
      title: "Created on",
      render: (invite) => (
        <span> {new Date(invite.created_at).toUTCString()}</span>
      ),
    },
    {
      title: "Expires on",
      render: (invite) => (
        <span> {new Date(invite.expiry_date).toUTCString()}</span>
      ),
    },
  ];

  return (
    <div>
      <DataTable
        tableHeader="User Invitations"
        data={invites}
        columns={inviteColumns}
        actions={inviteActions}
        itemsPerPage={4}
      />
    </div>
  );
};
