import { useState } from "react";
import { useFormik } from "formik";
import { ChartScatter, Handshake } from "@phosphor-icons/react";
import {  Select,  } from "@mantine/core";
import { Button } from "modules/@core/components/Button";
import { useCreateUserStrategy } from "../hooks/useCreateUserStrategy";
import { useGetStrategies } from "../hooks/useGetStrategies";
import { useGetBrokers } from "modules/broker/hooks/useGetBrokers";
import { CreateUserStrategyRequest, Strategy } from "../types";
import { Broker } from "modules/broker/types";
import { InputField } from "modules/@core/components/InputField";
import { StrategyInputField } from "./StrategyParameterInput";
import CreateStrategySchema from "../models/createStrategy";

interface AddStrategyModalProps {
  userId: number;
}

interface DynamicStrategyParameters {
  [key: string]: number;
}

interface FormValues extends Omit<CreateUserStrategyRequest, "parameters"> {
  parameters: DynamicStrategyParameters;
}

export const AddStrategyModal = ({ userId }: AddStrategyModalProps) => {
  const { mutate: createUserStrategy, isPending } =
    useCreateUserStrategy(userId);

  // Fetch strategies and brokers
  const { data: strategies, isLoading: isStrategiesLoading } = useGetStrategies(
    { skip: 0, limit: 100 }
  );
  const { data: brokers, isLoading: isBrokersLoading } = useGetBrokers({
    skip: 0,
    limit: 100,
  });

  // State to store the selected strategy details
  const [selectedStrategy, setSelectedStrategy] = useState<Strategy | null>(
    null
  );

  // Transform strategies and brokers into a format suitable for the Select component
  const strategyOptions =
    strategies?.map((strategy) => ({
      value: strategy.id.toString(),
      label: strategy.name,
    })) || [];

  const brokerOptions =
    brokers?.map((broker: Broker) => ({
      value: broker.id.toString(),
      label: broker.nick_name,
    })) || [];

  const initialValues: CreateUserStrategyRequest = {
    user_id: userId.toString(),
    strategy_id: "",
    broker_id: "",
    parameters: {},
    symbol: "",
    timeframe: "",
    risk_per_trade: "",
  };

  const formik = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: CreateStrategySchema(selectedStrategy?.type),
    onSubmit: (values) => {
      console.log("Attempting to submit");
      createUserStrategy(values);
    },
  });

  // Handle strategy selection
  const handleStrategyChange = (value: string) => {
    const strategy =
      strategies?.find((strategy) => strategy.id.toString() === value) || null;
    if (strategy && strategy.id.toString() !== formik.values.strategy_id) {
      setSelectedStrategy(strategy);
      console.log(strategy);
      formik.setFieldValue("strategy_id", value);
    }
  };

  return (
    <div>
      <ChartScatter  size={32} className="text-primary-500" />
      <h3 className="font-bold text-lg my-2">Add Strategy</h3>
      <p>Enter the details for the strategy you would like to add below</p>
      <form onSubmit={formik.handleSubmit}>
        {/* Strategy Select */}
        <Select
          label="Strategy"
          description={selectedStrategy && selectedStrategy.description}
          placeholder="Select a strategy"
          data={strategyOptions}
          value={formik.values.strategy_id}
          onChange={handleStrategyChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.strategy_id && formik.errors.strategy_id
              ? formik.errors.strategy_id
              : undefined
          }
          disabled={isStrategiesLoading}
        />
        {/* Broker Select */}
        <Select
          label="Broker"
          placeholder="Select a broker"
          data={brokerOptions}
          value={formik.values.broker_id}
          onChange={(value) => formik.setFieldValue("broker_id", value)}
          onBlur={formik.handleBlur}
          error={
            formik.touched.broker_id && formik.errors.broker_id
              ? formik.errors.broker_id
              : undefined
          }
          disabled={isBrokersLoading}
        />
        {selectedStrategy && (
          <StrategyInputField
            strategyName={selectedStrategy?.name}
            setFieldValue={formik.setFieldValue}
          />
        )}
        <InputField
          label="Symbol"
          name="symbol"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.symbol}
          error={
            formik.touched.symbol && formik.errors.symbol
              ? formik.errors.symbol
              : undefined
          }
        />
        <Select
          label="Timeframe"
          placeholder="Select your timeframe"
          data={["1D", "1M", "1YR"]}
          value={formik.values.timeframe}
          onChange={(value) => formik.setFieldValue("timeframe", value)}
          onBlur={formik.handleBlur}
          error={
            formik.touched.timeframe && formik.errors.timeframe
              ? formik.errors.timeframe
              : undefined
          }
          disabled={isBrokersLoading}
        />
        <Select
          label="Risk Per Trade"
          placeholder="Select your risk per trade"
          data={["5", "10", "35"]}
          onChange={(value) => formik.setFieldValue("risk_per_trade", value)}
          value={formik.values.risk_per_trade}
          onBlur={formik.handleBlur}
          error={
            formik.touched.risk_per_trade && formik.errors.risk_per_trade
              ? formik.errors.risk_per_trade
              : undefined
          }
          disabled={isBrokersLoading}
        />
        {/* Submit Button */}
        <Button
          type="submit"
          className="mt-5"
          disabled={isPending || isStrategiesLoading || isBrokersLoading}
          loading={isPending}
        >
          {isPending ? "Creating..." : "Add Strategy"}
        </Button>
      </form>
    </div>
  );
};

export default AddStrategyModal;
