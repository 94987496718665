import { RouteObject } from "react-router-dom";
import { StrategiesDashboard } from "../pages/StrategiesDashboard";

export const strategyRoutes: RouteObject[] = [
  {
    path: "strategies/*",
    children: [
      {
        path: "",
        element: <StrategiesDashboard />,
      },
      {
        path: ":strategyId",
      },
    ],
  },
];
