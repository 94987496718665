import { RouteObject } from "react-router-dom";
import GuardedRoute from "routes/GuardedRoute";
import { Dashboard } from "modules/dashboard/pages/Dashboard";
import { Overview } from "modules/dashboard/pages/Overview";
import { userRoutes } from "modules/user/routes";
import { brokerRoutes } from "modules/broker/routes";
import { strategyRoutes } from "modules/strategy/routes";

export const dashboardRoutes: RouteObject[] = [
  {
    path: "dashboard/*",
    element: <GuardedRoute component={<Dashboard />} />,
    children: [
      {
        path: "overview",
        element: <Overview />,
      },
      ...userRoutes,
      ...brokerRoutes,
      ...strategyRoutes,
    ],
  },
];
