import * as Yup from "yup";
import { StrategyType } from "../types"; // ensure this import path is correct

const parameterSchemas = {
  [StrategyType.MOVING_AVERAGE_CROSSOVER]: Yup.object().shape({
    fast_period: Yup.number()
      .required("Fast period is required")
      .positive("Fast period must be a positive number"),
    slow_period: Yup.number()
      .required("Slow period is required")
      .positive("Slow period must be a positive number"),
  }),
  [StrategyType.BOLLINGER_BANDS]: Yup.object().shape({
    window: Yup.number()
      .required("Window is required")
      .positive("Window must be a positive number"),
    std: Yup.number()
      .required("Standard Deviation is required")
      .positive("Standard Deviation must be a positive number"),
  }),
  [StrategyType.EXPONENTIAL_MOVING_AVERAGE]: Yup.object().shape({
    ema_period: Yup.number()
      .required("EMA period is required")
      .positive("EMA period must be a positive number"),
  }),
  // Add other strategies and their parameter schemas as needed
};

const getDynamicParametersSchema = (strategyType: StrategyType | undefined) =>
  strategyType ? parameterSchemas[strategyType] : Yup.object().shape({});

const CreateStrategySchema = (selectedStrategyType: StrategyType | undefined) =>
  Yup.object().shape({
    user_id: Yup.number()
      .required("User ID is required")
      .positive("User ID must be a positive number")
      .integer("User ID must be an integer"),
    strategy_id: Yup.number()
      .required("Strategy ID is required")
      .positive("Strategy ID must be a positive number")
      .integer("Strategy ID must be an integer"),
    broker_id: Yup.number()
      .required("Broker ID is required")
      .positive("Broker ID must be a positive number")
      .integer("Broker ID must be an integer"),
    parameters: getDynamicParametersSchema(selectedStrategyType),
    symbol: Yup.string().required("Symbol is required"),
    timeframe: Yup.string().required("Timeframe is required"),
    risk_per_trade: Yup.number()
      .required("Risk per trade is required")
      .positive("Risk per trade must be a positive number"),
  });

export default CreateStrategySchema;
