import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "modules/@core/lib/axios";
import type { Broker } from "../types";

const queryKeys = {
  brokers: ["brokers", { skip: 0, limit: 100 }],
};

const connectBroker = async (brokerId: number) => {
    return await axios.post(`broker/${brokerId}/connect`);
}
export const useConnectBroker = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, number, { previousBrokers?: Broker[] }>({
    mutationFn: async (brokerId) => {
      // Perform the disconnect action
    connectBroker(brokerId)

      // Optimistic update: update the query data for 'brokers'
      queryClient.setQueryData<Broker[]>(queryKeys.brokers, (old) => {
        return old
          ? old.map((broker) =>
              broker.id === brokerId
                ? { ...broker, isConnected: false }
                : broker
            )
          : [];
      });
    },

    // When mutation is successful, refetch the 'brokers' query
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.brokers });
    },
    // If mutation fails, rollback the optimistic update
    onError: (err, brokerId, context) => {
      queryClient.setQueryData(queryKeys.brokers, context?.previousBrokers);
    },
  });
};
